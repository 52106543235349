import styled from "styled-components";
import { BodyText } from "@ui/elements/BodyText";
import { isNullish } from "@/shared/helpers/isNullish";

export const Select = styled(BodyText)<{
  bgSrc?: string;
}>`
  appearance: none;
  border: none;
  border-radius: ${(p) => p.theme.roundedCorners.regular};
  padding: 0.25rem;
  padding-left: 2.125rem;
  ${(p) => !isNullish(p.bgSrc) && `background-image: url(${p.bgSrc});`}
  background-repeat: no-repeat;
  background-size: 1.5rem auto;
  background-position: 0.4rem center;
  background-color: ${(p) => p.theme.colors.elevatedBackground};
  height: 2.75rem;
`;
