import "instantsearch.css/themes/satellite.css";
import { Configure, InstantSearch, SearchBox } from "react-instantsearch";
import React, { FC, useMemo, useState } from "react";
import { getSearchClient } from "@features/clips/services/getSearchClient";
import { VideoSearchHitsList } from "@ui/blocks/VideoSearchHitsList";
import { SearchboxWrapper } from "@features/clips/elements/SearchboxWrapper";
import { ScreenName, ScreenNameType } from "@features/tracking/screen-names";
import { useLocale } from "@/shared/hooks/useLocale";
import { useLocation } from "react-router-dom";
import { usePageTracking } from "@features/tracking/hooks/usePageTracking";
import { useScreenName } from "@features/tracking/hooks/useScreenName";
import { useGetLocationQuery } from "@features/tracking/services/geolocationApi";
import { FontSize } from "@themeTypes/FontSizeType";
import { LogoLoadingSpinner } from "@ui/blocks/LoadingIcon/LoadinIcon";
import { LogoSpinnerCentered } from "@ui/blocks/LoadingIcon/LogoSpinnerCentered";
import { LayoutGrid } from "@ui/elements/LayoutGrid";
import { Title1 } from "@features/clips/elements/Title1";
import { Title2 } from "@features/clips/elements/Title2";
import { BurgerMenu } from "@ui/blocks/BurgerMenu";
import filtersIconLight from "/assets/icons/filter_light.svg";
import filtersIconDark from "/assets/icons/filter_dark.svg";
import { useAppSelector } from "@/shared/hooks/appHooks";
import { selectIsDarkTheme } from "@features/pageLayout/store/pageLayoutSlice";
import { GridArea } from "@ui/elements/GridArea";
import { FiltersClipsWrappers } from "@features/clips/elements/FiltersClipsWrapper";
import { ScrollToTopButton } from "@ui/blocks/ScrollToTopButton";
import { EmptyState } from "@ui/blocks/EmptyState";

// Will only be used on clips and highlights pathname
// if the page is reused, add appropriate pathname and their matching ScreenNames
const screenFromPathname: Record<string, ScreenNameType> = {
  "/clips": ScreenName.Clips,
  "/highlights": ScreenName.Highlights,
};

export const ClipsPage: FC = () => {
  const searchClient = useMemo(() => getSearchClient(), []);
  const { data, isLoading, isSuccess } = useGetLocationQuery();
  const [filtersDrawerOpened, setFiltersDrawerOpened] = useState(false);
  /*  const [isShowEmptyState, setIsShowEmptyState] = useState(false);*/
  const isDarkTheme = useAppSelector(selectIsDarkTheme);
  const { locale, t } = useLocale();
  const { pathname } = useLocation();

  const isHighlights = pathname === "/highlights";

  useScreenName(screenFromPathname[pathname]);
  usePageTracking();

  if (isLoading) {
    return (
      <LogoSpinnerCentered>
        <LogoLoadingSpinner />
      </LogoSpinnerCentered>
    );
  }

  const element = isSuccess && (
    <InstantSearch
      searchClient={searchClient}
      indexName="production.sorted.freshness"
    >
      <Configure
        hitsPerPage={40}
        filters={`languages:${locale} AND ${isHighlights ? "category:highlights" : "category:clip"} AND contentType:horizontal_video AND (geoAvailability:${data} OR geoAvailability:world)`}
      />
      <div style={{ position: "relative" }}>
        <LayoutGrid>
          <BurgerMenu
            onBurgerClick={() => setFiltersDrawerOpened(!filtersDrawerOpened)}
            iconSrc={isDarkTheme ? filtersIconDark : filtersIconLight}
            iconAlt="filters-icon"
            absolutePosition={{
              right: "1.25rem",
              top: "2.5rem",
              left: "auto",
            }}
          />
          <ScrollToTopButton
            message={t("BACK_TO_TOP")}
            isDarkMode={isDarkTheme}
            scrollToTopThreshHold={500}
          ></ScrollToTopButton>
          <GridArea column={"1/2"} row={"1/2"}>
            <Title1 size={FontSize.DrukTitle_4}>
              {isHighlights
                ? t("NAVIGATION_HIGHLIGHTS")
                : t("NAVIGATION_VIDEOS")}
            </Title1>
          </GridArea>

          <GridArea column={"1/2"} row={"2/3"}>
            <SearchboxWrapper>
              <SearchBox placeholder="Search video library..." />
            </SearchboxWrapper>
          </GridArea>

          <GridArea column={"1/2"} row={"3/4"}>
            <VideoSearchHitsList
              emptyState={{
                title: t("404_NOT_FOUND_TITLE"),
                message: t("EMPTY_SEARCH_MESSAGE"),
                image: {
                  alt: t("HAND_WITH_CARD_ALT_TEXT"),
                  src: "https://images.onefootball.com/cw/icons/hand-with-yellow-card-v2.svg",
                  width: 200,
                  height: 200,
                },
              }}
            />
          </GridArea>

          <GridArea column={"2/3"} row={"1/2"}>
            <Title2 size={FontSize.Title_5}>{t("FILTERS")}</Title2>
          </GridArea>

          <GridArea column={"2/3"} row={"2/4"}>
            <FiltersClipsWrappers
              isDarkTheme={isDarkTheme}
              filtersDrawerOpened={filtersDrawerOpened}
              onFiltersDrawerOutsideClick={() => setFiltersDrawerOpened(false)}
            />
          </GridArea>
        </LayoutGrid>
      </div>
    </InstantSearch>
  );
  return element;
};
