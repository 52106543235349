import { FC } from "react";
import { useParams } from "react-router";
import { useGetCreatorDetailQuery } from "@features/creatorDetail/services/creatorDetailApi";
import { isNullish } from "@/shared/helpers/isNullish";
import { useLocale } from "@/shared/hooks/useLocale";
import { CardPartnerNameWrapper } from "@/shared/ui/elements/CardPartnerNameWrapper";
import { ImageWithFallBack } from "@/shared/ui/blocks/ImageWithFallBack";
import { BannerWrapper } from "@/features/creatorDetail/elements/BannerWrapper";
import { CardCreatorProfile } from "../elements/CardCreatorProfile";
import { CreatorProfileTitle } from "../elements/CreatorProfileTitle";
import { DEFAULT_BANNER_IMAGE } from "../constants/creatorDetailConstants";
import { CreatorHeroImg } from "../elements/CreatorHeroImg";

export const CreatorHeaderContainer: FC = () => {
  const params = useParams();
  const { locale } = useLocale();
  const { data, isLoading } = useGetCreatorDetailQuery({
    locale,
    providerIdOrHandle: params.id ?? "1",
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isNullish(data)) {
    return null;
  }

  return (
    <>
      <BannerWrapper>
        <CreatorHeroImg
          width={1024}
          height={200}
          src={data.banner_url === "" ? DEFAULT_BANNER_IMAGE : data.banner_url}
          alt={data.display_name}
          isPreloaded
        />
      </BannerWrapper>
      <CardPartnerNameWrapper>
        <ImageWithFallBack
          width={150}
          height={150}
          src={data.logo}
          alt={data.display_name}
        />
        <CardCreatorProfile>
          <CreatorProfileTitle>{data.display_name}</CreatorProfileTitle>
          <p>{data.handle}</p>
          <p>{data.bio}</p>
          {data.imprint_url && (
            <p>
              <span>imprint: </span>
              <a href={data.imprint_url} target="_blank">
                {data.imprint_url}
              </a>
            </p>
          )}
          {data.social_link_url && (
            <p>
              <span>social: </span>
              <a href={data.social_link_url} target="_blank">
                {data.social_link_url}
              </a>
            </p>
          )}
        </CardCreatorProfile>
      </CardPartnerNameWrapper>
    </>
  );
};
