export const ScreenName = {
  Page404: "Page404",
  Clips: "Clips",
  ClipsDetail: "ClipsDetail",
  CreatorDetail: "CreatorDetail",
  Error: "Error",
  Highlights: "Highlights",
  Home: "Home",
  LiveMatches: "LiveMatches",
  Login: "Login",
  MatchDetail: "MatchDetail",
  News: "News",
  OF: "OF",
  Profile: "Profile",
  Register: "Register",
  Unknown: "Unknown",
} as const;

export type ScreenNameType = (typeof ScreenName)[keyof typeof ScreenName];
