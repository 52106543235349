import { FC, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useLocale } from "@/shared/hooks/useLocale";
import { useGetCreatorVideosQuery } from "../services/creatorDetailApi";
import { isNullish } from "@/shared/helpers/isNullish";
import { VideoCardsTeaserWrapper } from "@/shared/ui/elements/VideoCardsTeaserWrapper";
import { VideoCard } from "@/shared/ui/blocks/VideoCard";
import { AppRoutes } from "@/shared/constants/Routes";
import { formatDuration } from "@/shared/helpers/formatDuration";
import { timeAgo } from "@/shared/helpers/timeAgo";
import { CreatorVideosDTO } from "../types/CreatorVideosDTO";
import { trackingService } from "@/features/tracking/services/trackingService";
import { EventName } from "@/modules/tracking/constants/EventName";
import { SectionTypes } from "@/shared/types/SectionTypes";
import { LogoSpinnerCentered } from "@/shared/ui/blocks/LoadingIcon/LogoSpinnerCentered";
import { LogoLoadingSpinner } from "@/shared/ui/blocks/LoadingIcon/LoadinIcon";

export const CreatorVideosContainer: FC = () => {
  const params = useParams();
  const { locale: localeFromHook } = useLocale();
  const [page, setPage] = useState(1);
  const [items, setItems] = useState<CreatorVideosDTO["data"]>([]);
  const [locale, setLocale] = useState(localeFromHook);
  const { data, isFetching } = useGetCreatorVideosQuery({
    locale,
    providerIdOrHandle: params.id ?? "1",
    page,
  });
  const sentinelRef = useRef<HTMLLIElement | null>(null);
  const sentinelRefCurrent = sentinelRef.current;
  const isLastPage = data?.data.length === 0;

  // This useEffect handles appending new data when fetched
  useEffect(() => {
    if (data?.data) {
      setItems((prevItems) => [...prevItems, ...data.data]);
    }
  }, [data]);

  // This useEffect detects when the user scrolls to the bottom and loads more data
  useEffect(() => {
    if (sentinelRefCurrent !== null && !isLastPage && !isFetching) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setPage((prevPage) => prevPage + 1);
          }
        });
      });

      observer.observe(sentinelRefCurrent);

      return () => {
        observer.disconnect();
      };
    }
  }, [isLastPage, sentinelRefCurrent, isFetching]);

  // This useEffect resets items and page when locale changes
  useEffect(() => {
    setItems([]);
    setPage(1);
    setLocale(localeFromHook);
  }, [localeFromHook]);

  if (isFetching) {
    return (
      <LogoSpinnerCentered>
        <LogoLoadingSpinner />
      </LogoSpinnerCentered>
    );
  }

  if (isNullish(data)) {
    return null;
  }

  return (
    <VideoCardsTeaserWrapper>
      {items.map((video) => (
        <VideoCard
          duration={formatDuration(video.duration_in_seconds)}
          key={video.id}
          publishTime={timeAgo(video.published_since, locale)}
          imageObject={{ path: video.thumbnail_url, alt: video.title }}
          title={video.title}
          onClick={() => {
            void trackingService.sendEvent({
              name: EventName.CardClicked,
              properties: {
                card_type: SectionTypes.channel,
                item_id: video.id,
              },
            });
          }}
          url={`${AppRoutes.Clips}/${video.id}`}
        />
      ))}
      <li
        style={{ visibility: "hidden" }}
        ref={sentinelRef}
        aria-hidden="true"
      />
    </VideoCardsTeaserWrapper>
  );
};
