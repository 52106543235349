import { ImageGradient } from "@features/featuredMatch/elements/ImageGradient";
import { ContentWrapper } from "@features/featuredMatch/elements/ContentWrapper";
import { MatchInfoWrapper } from "@features/featuredMatch/elements/MatchInfoWrapper";
import { MatchInfo } from "@features/featuredMatch/elements/MatchInfo";
import { MatchInfoItem } from "@features/featuredMatch/elements/MatchInfoItem";
import { CardPartnerNameText } from "@ui/elements/CardPartnerNameText";
import { TitleDruk } from "@ui/elements/TitleDruk";
import { FontSize } from "@themeTypes/FontSizeType";
import { TeamLogoWrapper } from "@features/featuredMatch/elements/TeamLogoWrapper";
import { TeamLogoItem } from "@features/featuredMatch/elements/TeamLogoItem";
import { ActionWrapper } from "@features/featuredMatch/elements/ActionWrapper";
import { Wrapper } from "@features/featuredMatch/elements/Wrapper";
import Skeleton from "react-loading-skeleton";
import { FC } from "react";

export const FeaturedMatchLoader: FC = () => {
  return (
    <Wrapper>
      <ImageGradient />
      <ContentWrapper>
        <MatchInfoWrapper>
          {/* MATCH INFO SECTION*/}
          <MatchInfo>
            {/* MATCH TIME*/}
            <MatchInfoItem>
              <CardPartnerNameText style={{ marginLeft: 0 }}>
                <Skeleton width="5rem" />
              </CardPartnerNameText>
            </MatchInfoItem>
            {/* COMPETITION LOGO AND NAME */}
            <MatchInfoItem>
              <Skeleton
                width={24}
                height={24}
                style={{ marginRight: "0.5rem" }}
              />
              <CardPartnerNameText style={{ marginLeft: 0 }}>
                <Skeleton width="5rem" />
              </CardPartnerNameText>
            </MatchInfoItem>

            {/* PARTNER LOGO AND NAME */}
            <MatchInfoItem>
              <Skeleton
                width={24}
                height={24}
                style={{ marginRight: "0.5rem" }}
              />
              <CardPartnerNameText>
                <Skeleton width="5rem" />
              </CardPartnerNameText>
            </MatchInfoItem>
          </MatchInfo>

          {/* MATCH TEAMS SECTION*/}
          <div>
            <TitleDruk size={FontSize.DrukTitle_1}>
              <Skeleton width="50%" />
              <Skeleton width="50%" />
            </TitleDruk>
          </div>

          <TeamLogoWrapper>
            <TeamLogoItem>
              <Skeleton width={88} height={88} />
            </TeamLogoItem>
            <TeamLogoItem>
              <Skeleton width={88} height={88} />
            </TeamLogoItem>
          </TeamLogoWrapper>
          <ActionWrapper>
            <Skeleton width="8rem" height="2.75rem" />
            <Skeleton width="8rem" height="2.75rem" />
          </ActionWrapper>
        </MatchInfoWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};
