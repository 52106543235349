import React, { FC, useEffect, useMemo, useRef } from "react";
import { VideoCardsTeaserWrapper } from "@ui/elements/VideoCardsTeaserWrapper";
import { LiveMatchCard } from "@ui/blocks/LiveMatchCard";
import { renderLiveMatchesLoading } from "@features/discovery/helpers/renderLiveMatchesLoading";
import { isNullish } from "@/shared/helpers/isNullish";
import {
  discoveryApi,
  useGetLiveMatchesRailQuery,
} from "@features/discovery/services/discoveryApi";
import { NUMBER_OF_ITEMS_PER_PAGE } from "@features/liveMatches/constants/LiveMatchesConstants";
import { useLocale } from "@/shared/hooks/useLocale";
import { useAppDispatch, useAppSelector } from "@/shared/hooks/appHooks";
import {
  selectCurrentPage,
  selectFilteredCompetition,
  selectFilteredPrice,
  setCurrentPage,
  setFilteredCompetition,
} from "@features/liveMatches/store/liveMatchesSlice";
import { useSearchParams } from "react-router-dom";
import { trackingService } from "@features/tracking/services/trackingService";
import { EventName } from "@/modules/tracking/constants/EventName";
import { SectionTypes } from "@/shared/types/SectionTypes";
import { useInfiniteScroll } from "@/shared/hooks/useInfiniteScroll";

export const LiveMatchCardListContainer: FC = () => {
  const observerTarget = useRef<HTMLDivElement | null>(null);
  const { locale } = useLocale();
  const dispatch = useAppDispatch();
  const currentPage = useAppSelector(selectCurrentPage);
  const filteredCompetition = useAppSelector(selectFilteredCompetition);
  const filteredPrice = useAppSelector(selectFilteredPrice);

  const [searchParams, setSearchParams] = useSearchParams();
  const competitionID = searchParams.get("competitionID");

  const { data, isSuccess, isFetching, isError } = useGetLiveMatchesRailQuery({
    locale,
    offset: currentPage * NUMBER_OF_ITEMS_PER_PAGE,
    competitionID: competitionID ?? filteredCompetition,
    price: filteredPrice,
  });

  const sendCardClicked = ({
    price,
    itemId,
  }: {
    price: string | undefined;
    itemId: string;
  }) => {
    void trackingService.sendEvent({
      name: EventName.CardClicked,
      properties: {
        card_type: SectionTypes.match,
        price,
        item_id: itemId,
      },
    });
  };

  const handleOnEndReached = (): void => {
    if (
      isError ||
      isFetching ||
      (isSuccess && !isNullish(data.noMorePages) && data.noMorePages)
    ) {
      return;
    }
    dispatch(setCurrentPage(currentPage + 1));
  };

  useInfiniteScroll({
    ref: observerTarget,
    onEndReached: handleOnEndReached,
  });

  useEffect(() => {
    if (filteredCompetition !== "") {
      setSearchParams({ competitionID: filteredCompetition });
    }

    if (competitionID !== filteredCompetition && !isNullish(competitionID)) {
      dispatch(setFilteredCompetition(competitionID));
    }
  }, [competitionID, dispatch, filteredCompetition, setSearchParams]);

  // Since we removed caching responses and fetch on each mount
  // we have to reset the state to avoid duplicate data
  useEffect(() => {
    return () => {
      dispatch(discoveryApi.util.resetApiState());
    };
  }, [dispatch]);

  const VideoCardItems = useMemo(
    () =>
      isSuccess &&
      data.upcomingMatches?.map((item, i) => {
        const onClickEventProperties = {
          price: isNullish(item.price?.value)
            ? undefined
            : String(item.price.value),
          itemId: item.matchId,
        };
        return (
          <LiveMatchCard
            card={item}
            key={item.uiKey}
            onClick={() => {
              sendCardClicked(onClickEventProperties);
            }}
          />
        );
      }),
    [data?.upcomingMatches, isSuccess],
  );

  return (
    <VideoCardsTeaserWrapper>
      {isFetching ? renderLiveMatchesLoading(12) : VideoCardItems}

      {/* IntersectionObserver ref element to trigger infinite scroll */}
      {!isNullish(data?.upcomingMatches) && data.upcomingMatches.length > 5 && (
        <div ref={observerTarget} style={{ height: "1rem" }}></div>
      )}
    </VideoCardsTeaserWrapper>
  );
};
