import { VideoPlayerWrapper } from "@ui/blocks/VideoPlayerRelatedVideos/elements/VideoPlayerWrapper";
import { VideosSectionWrapper } from "@ui/blocks/VideoPlayerRelatedVideos/elements/VideosSectionWrapper";
import { FC, useMemo, useState } from "react";
import { BAIT_SCRIPT_URL } from "@/shared/constants/baitScriptUrl";
import { VideoPlayer } from "@ui/blocks/VideoPlayer";
import { MatchScoreData } from "@features/matchDetail/types/MatchScoreData";
import { VideoMatchScore } from "@ui/blocks/VideoMatchScore";
import { RelatedVideos, RelatedVideosProps } from "@ui/blocks/RelatedVideos";
import { VideoPublisherImprintType } from "@/shared/types/VideoPublisherImprintType";
import { VideoPlayerTitle } from "@ui/blocks/VideoPlayerTitle";
import { VideoProvider, VideoProviderProps } from "@ui/blocks/VideoProvider";
import { isNullish } from "@/shared/helpers/isNullish";
import {
  VideoDescription,
  VideoDescriptionProps,
} from "@ui/blocks/VideoDescription";
import { Nullable } from "@/shared/types/Nullable";
import { VideoJSPlayerProps } from "@ui/blocks/VideoPlayer/types/VideoJSPlayerProps";
import { OttBanner, OttBannerProps } from "@ui/blocks/OttBanner";
import { VideoPlayerEmpty } from "@ui/blocks/VideoPlayerEmpty";
import { HorizontalSeparator } from "@ui/elements/HorizontalLine";

export interface VideoPlayerRelatedVideosProps {
  matchScoreData: MatchScoreData | null;
  videoTitle?: string;
  videoPublisher?: VideoPublisherImprintType;
  videoPlayer?: Nullable<Omit<VideoJSPlayerProps, "adBlockerBaitScriptUrl">>;
  videoProvider?: VideoProviderProps;
  videoDescription?: Nullable<VideoDescriptionProps>;
  relatedVideos?: RelatedVideosProps;
  ottBanner?: OttBannerProps;
}

export const VideoPlayerRelatedVideos: FC<VideoPlayerRelatedVideosProps> = (
  props,
) => {
  const {
    matchScoreData,
    relatedVideos,
    videoTitle,
    videoProvider,
    videoPlayer,
    videoDescription,
    ottBanner,
  } = props;

  const [shouldShowScoreInfo, setShouldShowScoreInfo] = useState(true);
  const attachMatchInfo = () => {
    setShouldShowScoreInfo(true);

    setTimeout(() => {
      setShouldShowScoreInfo(false);
    }, 4000);
  };

  const MemoizedVideoPlayer = useMemo(
    () =>
      !isNullish(videoPlayer) && !isNullish(videoPlayer.config) ? (
        <VideoPlayer
          {...videoPlayer}
          adBlockerBaitScriptUrl={BAIT_SCRIPT_URL}
          events={{
            onReady: attachMatchInfo,
            onPlay: videoPlayer.events?.onPlay,
          }}
        />
      ) : (
        <VideoPlayerEmpty link={ottBanner?.link} />
      ),
    [videoPlayer, ottBanner],
  );

  return (
    <VideosSectionWrapper>
      <VideoPlayerWrapper
        data-testid="video-player"
        onMouseEnter={() => setShouldShowScoreInfo(true)}
        onMouseLeave={() => setShouldShowScoreInfo(false)}
      >
        {MemoizedVideoPlayer}
      </VideoPlayerWrapper>

      {!isNullish(videoTitle) && (
        <div>
          <VideoPlayerTitle videoTitle={videoTitle} />
          {!isNullish(videoPlayer?.config) && (
            <VideoMatchScore
              shouldShowScore={shouldShowScoreInfo}
              data={matchScoreData}
            />
          )}
        </div>
      )}

      {!isNullish(ottBanner) && (
        <>
          <OttBanner {...ottBanner} />
        </>
      )}
      {!isNullish(videoProvider) && (
        <VideoProvider
          {...videoProvider}
          providerLogo={{
            ...videoProvider.providerLogo,
            width: 38,
            height: 38,
          }}
        />
      )}

      {!isNullish(videoDescription?.title) &&
        !isNullish(videoDescription.description) && (
          <>
            <VideoDescription {...videoDescription}></VideoDescription>
          </>
        )}
      {!isNullish(relatedVideos) &&
        !isNullish(relatedVideos.relatedVideosCards) &&
        relatedVideos.relatedVideosCards.length > 0 && (
          <>
            <HorizontalSeparator />
            <RelatedVideos {...relatedVideos} />
          </>
        )}
    </VideosSectionWrapper>
  );
};
