import { FC } from "react";
import { useGetFeaturedMatch } from "@features/featuredMatch/hooks/useGetFeaturedMatch";
import { isNullish } from "@/shared/helpers/isNullish";
import { FeaturedImage } from "@features/featuredMatch/elements/FeaturedImage";
import { ImageGradient } from "@features/featuredMatch/elements/ImageGradient";
import { Wrapper } from "@features/featuredMatch/elements/Wrapper";
import { ContentWrapper } from "@features/featuredMatch/elements/ContentWrapper";
import { TitleDruk } from "@ui/elements/TitleDruk";
import { FontSize } from "@themeTypes/FontSizeType";
import { ImageWithFallBack } from "@ui/blocks/ImageWithFallBack";
import { MatchInfoWrapper } from "@features/featuredMatch/elements/MatchInfoWrapper";
import { MatchInfo } from "@features/featuredMatch/elements/MatchInfo";
import { MatchInfoItem } from "@features/featuredMatch/elements/MatchInfoItem";
import { CardPartnerNameText } from "@ui/elements/CardPartnerNameText";
import { TeamLogoWrapper } from "@features/featuredMatch/elements/TeamLogoWrapper";
import { TeamLogoItem } from "@features/featuredMatch/elements/TeamLogoItem";
import { Button, ButtonVariant } from "@ui/elements/Button";
import { TitleText } from "@ui/elements/TitleText";
import { ActionWrapper } from "@features/featuredMatch/elements/ActionWrapper";
import { Link } from "react-router-dom";
import { AppRoutes } from "@/shared/constants/Routes";
import { useLocale } from "@/shared/hooks/useLocale";
import { Price } from "@features/featuredMatch/elements/Price";
import { FeaturedMatchLoader } from "@features/featuredMatch/blocks/FeaturedMatchLoader";
import { FeaturedTitleWrapper } from "@features/featuredMatch/elements/FeaturedTitle";

export const FeaturedMatchContainer: FC = () => {
  const { featuredMatch, isLoading, isSuccess } = useGetFeaturedMatch();
  const { t } = useLocale();

  if (isLoading || !isSuccess || isNullish(featuredMatch)) {
    return <FeaturedMatchLoader />;
  }

  if (isNullish(featuredMatch.stadium.image)) {
    return null;
  }

  return (
    <Wrapper>
      <FeaturedImage
        src={featuredMatch.stadium.image.path}
        darkModeSrc={featuredMatch.stadium.image.darkModePath}
        alt={featuredMatch.stadium.image.alt}
        isPreloaded
        height={500}
      />
      <ImageGradient />
      <ContentWrapper>
        <MatchInfoWrapper>
          {/* MATCH INFO SECTION*/}
          <MatchInfo>
            {/* MATCH TIME*/}
            <MatchInfoItem>
              <CardPartnerNameText style={{ marginLeft: 0 }}>
                {featuredMatch.kickoffFormatted} |{" "}
                {featuredMatch.kickoffTimeFormatted}
              </CardPartnerNameText>
            </MatchInfoItem>
            {/* COMPETITION LOGO AND NAME */}
            <MatchInfoItem>
              <ImageWithFallBack
                style={{ marginRight: "0.5rem" }}
                src={featuredMatch.competitionLogo.path}
                alt={featuredMatch.competitionLogo.alt}
                width={24}
              />
              <CardPartnerNameText style={{ marginLeft: 0 }}>
                {featuredMatch.competitionName}
              </CardPartnerNameText>
            </MatchInfoItem>

            {/* PARTNER LOGO AND NAME */}
            <MatchInfoItem>
              <ImageWithFallBack
                src={featuredMatch.partnerLogo.path}
                alt={featuredMatch.partnerLogo.alt}
                width={24}
                style={{ marginRight: "0.5rem" }}
              />
              <CardPartnerNameText>
                {featuredMatch.partnerName}
              </CardPartnerNameText>
            </MatchInfoItem>
          </MatchInfo>

          {/* MATCH TEAMS SECTION*/}
          <FeaturedTitleWrapper>
            <TitleDruk size={FontSize.DrukTitle_1}>
              {featuredMatch.homeTeam.name} - {featuredMatch.awayTeam.name}
            </TitleDruk>
          </FeaturedTitleWrapper>

          <TeamLogoWrapper>
            <TeamLogoItem>
              <ImageWithFallBack
                src={featuredMatch.homeTeam.imageObject.path}
                alt={featuredMatch.homeTeam.imageObject.alt}
                width={88}
                height={88}
              />
            </TeamLogoItem>
            <TeamLogoItem>
              <ImageWithFallBack
                src={featuredMatch.awayTeam.imageObject.path}
                alt={featuredMatch.awayTeam.imageObject.alt}
                width={88}
                height={88}
              />
            </TeamLogoItem>
          </TeamLogoWrapper>
          <ActionWrapper>
            <Button
              variant={ButtonVariant.fill}
              as={Link}
              to={`${AppRoutes.LiveMatches}/${featuredMatch.matchId}`}
            >
              <TitleText size={FontSize.Title_7}>Watch Now</TitleText>
            </Button>
            <Price>
              {isNullish(featuredMatch.price)
                ? `${t(`PRICE_FREE`)}`
                : `${featuredMatch.price.value} ${featuredMatch.price.currency}`}
            </Price>
          </ActionWrapper>
        </MatchInfoWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};
