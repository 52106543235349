export interface CollectorConfig {
  /**
   * The name of the service as identified in Honeycomb.
   * Used to categorize metrics and traces under this service name.
   */
  readonly serviceName: string;

  /**
   * The endpoint for the collector to send telemetry data.
   * This should be the Honeycomb API endpoint or an OpenTelemetry collector endpoint.
   */
  readonly collectorEndpoint: string;

  /**
   * Authorization token required for API access.
   * This token is used for authenticating the telemetry data sent to the endpoint.
   */
  readonly authorizationToken: string;
}

// Constant to define the service name for the collector
const OTEL_SERVICE_NAME = "tv-hub";

/**
 * Function to retrieve the collector configuration
 * @returns {CollectorConfig} - Returns the validated and structured collector configuration object
 *
 * This function reads environment variables and maps them to the
 * CollectorConfig structure. It assumes that `import.meta.env`
 * contains the necessary environment variables:
 * - serviceName is set to `OTEL_SERVICE_NAME` constant
 * - `VITE_OTEL_COLLECTOR_ENDPOINT`: Endpoint URL for telemetry data
 * - `VITE_OTEL_API_KEY`: Authorization token for the collector
 */
export const getCollectorConfig = (): CollectorConfig => {
  const { VITE_OTEL_COLLECTOR_ENDPOINT, VITE_OTEL_COLLECTOR_AUTH_TOKEN } =
    import.meta.env;

  return {
    serviceName: OTEL_SERVICE_NAME,
    collectorEndpoint: VITE_OTEL_COLLECTOR_ENDPOINT as string,
    authorizationToken: VITE_OTEL_COLLECTOR_AUTH_TOKEN as string,
  };
};

export const collectorConfig = getCollectorConfig();
