import {
  HoneycombWebSDK,
  WebVitalsInstrumentation,
} from "@honeycombio/opentelemetry-web";
import { getWebAutoInstrumentations } from "@opentelemetry/auto-instrumentations-web";

import { CollectorConfig } from "../collector/config";

/**
 * Sets up and returns a Honeycomb SDK instance for observability.
 *
 * @param {CollectorConfig} config - The configuration object containing necessary
 * details such as service name, endpoint, and authorization token.
 *
 * @returns {HoneycombWebSDK} - An instance of the HoneycombWebSDK configured with
 * the provided parameters, ready to collect and send telemetry data.
 *
 * This function initializes the Honeycomb SDK using the provided collector configuration.
 * For more information on the Honeycomb SDK and configuration options,
 * refer to the official documentation:
 *
 * https://github.com/honeycombio/honeycomb-opentelemetry-web
 */
export const setupHoneycomb = (config: CollectorConfig): HoneycombWebSDK =>
  new HoneycombWebSDK({
    serviceName: config.serviceName,
    endpoint: config.collectorEndpoint,
    headers: {
      Authorization: `Basic ${config.authorizationToken}`,
    },

    // Instrumentations for automatic telemetry collection
    instrumentations: [
      getWebAutoInstrumentations(),
      new WebVitalsInstrumentation(),
    ],

    // Used with OpenTelemetry Collector.
    skipOptionsValidation: true,
  });
