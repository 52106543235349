import { useEffect } from "react";
import { observability } from "@/modules/observability";
import { isProd } from "@/shared/helpers/isProd";

export const useObservability = (): void => {
  useEffect(() => {
    if (isProd) {
      observability.start();
    }
  }, []);
};
