import { FC } from "react";
import { ScreenName } from "@features/tracking/screen-names";
import { usePageTracking } from "@features/tracking/hooks/usePageTracking";
import { useScreenName } from "@features/tracking/hooks/useScreenName";
import { EmptyState } from "@ui/blocks/EmptyState";
import { useLocale } from "@/shared/hooks/useLocale";

export const ErrorPage: FC = () => {
  const { t } = useLocale();

  useScreenName(ScreenName.Error);
  usePageTracking();

  return (
    <EmptyState
      title={t("500_ERROR_TITLE")}
      message={t("500_ERROR_MESSAGE")}
      image={{
        alt: t("HAND_WITH_CARD_ALT_TEXT"),
        src: "https://images.onefootball.com/cw/icons/hand-with-yellow-card-v2.svg",
        width: 200,
        height: 200,
      }}
    ></EmptyState>
  );
};
