import { EmptyState } from "@ui/blocks/EmptyState";
import { FC } from "react";
import { useLocale } from "@/shared/hooks/useLocale";
import { useScreenName } from "@features/tracking/hooks/useScreenName";
import { ScreenName } from "@features/tracking/screen-names";

export const Page404: FC = () => {
  const { t } = useLocale();
  useScreenName(ScreenName.Page404);

  return (
    <EmptyState
      title={t("404_NOT_FOUND_TITLE")}
      message={t("404_NOT_FOUND_MESSAGE")}
      image={{
        alt: t("HAND_WITH_CARD_ALT_TEXT"),
        src: "https://images.onefootball.com/cw/icons/hand-with-yellow-card-v2.svg",
        width: 200,
        height: 200,
      }}
    ></EmptyState>
  );
};
