import styled from "styled-components";
import { WithThemeType } from "@themeTypes/WithThemeType";
import { GlobalStylesConstants } from "@ui/elements/GlobalStyles/GlobalStylesConstants";

interface SingleFilterWrapperProps extends WithThemeType {
  collapsed: boolean;
}

export const SingleFilterWrapper = styled.div<SingleFilterWrapperProps>`
  display: flex;
  flex-direction: column;
  max-height: ${({ collapsed }) => (collapsed ? "4.5rem" : "25rem")};
  margin-bottom: 1.5rem;
  padding: 1.5rem 1.5rem ${({ collapsed }) => (collapsed ? "1.5rem" : "2rem")};
  background-color: ${({ theme }) => theme.colors.navigationBackground};
  transition: all 0.3s ease;
  overflow: hidden;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    display: ${({ collapsed }) => (collapsed ? "none" : "block")};
    height: calc(${GlobalStylesConstants.minSizeInteractiveElement} / 1.3);
    bottom: 2rem;
    left: 0;
    width: 100%;
    pointer-events: none;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      ${({ theme }) => theme.colors.elevatedBackground} 100%
    );
  }
`;
