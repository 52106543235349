import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import en from "../locales/en-US/tvhub.json";
import de from "../locales/de-DE/tvhub.json";
import es from "../locales/es-ES/tvhub.json";
import fr from "../locales/fr-FR/tvhub.json";
import it from "../locales/it-IT/tvhub.json";
import ptbr from "../locales/pt-BR/tvhub.json";

export const initTranslations = (): void => {
  void i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
      fallbackLng: "en",
      lowerCaseLng: true,
      resources: {
        en: {
          translation: en,
        },
        de: {
          translation: de,
        },
        es: {
          translation: es,
        },
        fr: {
          translation: fr,
        },
        it: {
          translation: it,
        },
        "pt-br": {
          translation: ptbr,
        },
      },
    });
};
