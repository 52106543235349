import { collectorConfig } from "./collector/config";
import { setupHoneycomb } from "./honeycomb/honeycomb";

export interface OtelSdk {
  start: () => void;
}

/**
 * Factory function to create an Observability instance.
 *
 * @param otelSdk - An observability SDK instance that implements the OtelSdk interface.
 * @returns {OtelSdk} - An object with a `start` method to initialize the SDK.
 */
export const createObservability = (otelSdk: OtelSdk): OtelSdk => {
  return {
    start: () => {
      otelSdk.start();
    },
  };
};

const otelSdk = setupHoneycomb(collectorConfig);

export const observability = createObservability(otelSdk);
