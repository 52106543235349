import { coerce, Infer, number, optional, string, type } from "superstruct";
import { formatDuration } from "@/shared/helpers/formatDuration";

export const VODSearchHitTypeStruct = type({
  provider: type({
    profileImage: string(),
    name: string(),
  }),
  duration: coerce(string(), number(), (value) => {
    return formatDuration(value);
  }),
  imageUrl: string(),
  title: string(),
  objectID: string(),
  publishedSinceDatetime: optional(string()),
});

export type VODSearchHitType = Infer<typeof VODSearchHitTypeStruct>;
