import styled from "styled-components";
import { GlobalStylesConstants } from "@ui/elements/GlobalStyles/GlobalStylesConstants";

export const ItemLabel = styled.label`
  display: grid;
  grid-auto-flow: column;
  gap: 0.5rem;
  align-items: center;
  grid-template-columns: 1.5rem max-content auto max-content;
  min-height: ${GlobalStylesConstants.minSizeInteractiveElement};
  height: fit-content;
`;
