import { type, string, Infer, nullable, optional } from "superstruct";
import { ImageObjectStruct } from "@/shared/structs/ImageObjectStruct";
import { VideoPlayerDataStruct } from "@/shared/types/VideoPlayerData";

const StreamDataStruct = type({
  matchID: string(),
  title: string(),
  partnerName: string(),
  partnerImage: ImageObjectStruct,
  videojsPlayer: VideoPlayerDataStruct,
});

export const StreamErrorDataStruct = type({
  title: string(),
  message: string(),
  type: string(),
});

export const StreamDTOStruct = type({
  uiKey: optional(string()),
  stream: optional(nullable(StreamDataStruct)),
  streamError: optional(StreamErrorDataStruct),
});

export type StreamDTO = Infer<typeof StreamDTOStruct>;
export type StreamErrorData = Infer<typeof StreamErrorDataStruct>;
