import { useInfiniteHits } from "react-instantsearch";
import React, { FC, useEffect, useRef } from "react";
import { VideoCard } from "@ui/blocks/VideoCard";
import {
  VODSearchHitType,
  VODSearchHitTypeStruct,
} from "@/shared/types/VODSearchHitType";
import { array, create } from "superstruct";
import { AppRoutes } from "@/shared/constants/Routes";
import { EventName } from "@/modules/tracking/constants/EventName";
import { SectionTypes } from "@/shared/types/SectionTypes";
import { VideoCardsTeaserWrapper } from "@/shared/ui/elements/VideoCardsTeaserWrapper";
import { trackingService } from "@features/tracking/services/trackingService";
import { EmptyState, EmptyStateProps } from "@ui/blocks/EmptyState";

export interface VideoSearchHitsListProps {
  emptyState: EmptyStateProps;
}

export const VideoSearchHitsList: FC<VideoSearchHitsListProps> = (props) => {
  const { emptyState, ...useInfiniteProps } = props;
  const { items, showMore, isLastPage } =
    useInfiniteHits<VODSearchHitType>(useInfiniteProps);

  const typedHits = create(
    items,
    array(VODSearchHitTypeStruct),
    "Can not create hits",
  );

  const sentinelRef = useRef<HTMLLIElement | null>(null);

  /*
  useEffect(() => {
    setIsShowEmptyState(typedHits.length === 0 && !isFirstPage);
  }, [isFirstPage, setIsShowEmptyState, typedHits]);
*/

  useEffect(() => {
    if (sentinelRef.current !== null) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !isLastPage) {
            showMore();
          }
        });
      });

      observer.observe(sentinelRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [isLastPage, showMore]);

  if (typedHits.length === 0) {
    return <EmptyState {...emptyState} />;
  }

  return (
    <VideoCardsTeaserWrapper>
      {typedHits.length > 0 &&
        typedHits.map((hit, i) => (
          <VideoCard
            duration={hit.duration}
            key={i}
            providerLogo={{
              path: hit.provider.profileImage,
              alt: hit.provider.name,
            }}
            providerName={hit.provider.name}
            imageObject={{ path: hit.imageUrl, alt: hit.title }}
            title={hit.title}
            onClick={() => {
              void trackingService.sendEvent({
                name: EventName.CardClicked,
                properties: {
                  card_type: SectionTypes.searchHits,
                  item_id: hit.objectID,
                },
              });
            }}
            url={`${AppRoutes.Clips}/${hit.objectID}`}
          />
        ))}
      <li
        style={{ visibility: "hidden" }}
        ref={sentinelRef}
        aria-hidden="true"
      />
    </VideoCardsTeaserWrapper>
  );
};
