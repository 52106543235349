import styled from "styled-components";
import { WithThemeType } from "@themeTypes/WithThemeType";
import { limit } from "@ui/elements/GlobalStyles/helpers/limit";

export const EmptyStateWrapper = styled.div<WithThemeType>`
  max-width: ${limit};
  margin-left: auto;
  margin-right: auto;
  display: grid;
  gap: 1rem;
  place-items: center;
  padding-top: 3.75rem;
  grid-auto-rows: max-content;
  text-align: center;
`;
