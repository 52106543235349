import React, { FC } from "react";
import { ItemLabel } from "@ui/blocks/CompetitionsFilterItem/elements/ItemLabel";
import { isNullish } from "@/shared/helpers/isNullish";
import {
  ImageWithFallBack,
  ImageWithFallBackProps,
} from "@ui/blocks/ImageWithFallBack";
import { BodyText } from "@ui/elements/BodyText";
import { ThemeColor } from "@themeTypes/ThemeColorType";
import { Input } from "@ui/blocks/CompetitionsFilterItem/elements/Input";
import { TickIcon } from "@ui/blocks/TickIcon";
import { EmptyCircle } from "@ui/blocks/CompetitionsFilterItem/elements/EmptyCircle";

export interface CompetitionsFilterItemProps {
  text: string;
  isSelected: boolean;
  onItemClick: (itemID: string) => void;
  numberOfAvailableMatches?: number;
  id?: string;
  icon?: ImageWithFallBackProps;
}

export const CompetitionsFilterItem: FC<CompetitionsFilterItemProps> = ({
  onItemClick,
  id = "empty",
  numberOfAvailableMatches,
  text,
  icon,
  isSelected,
}) => {
  return (
    <ItemLabel htmlFor={id === "" ? "all" : id}>
      <Input
        id={id === "" ? "all" : id}
        type="radio"
        value={id}
        checked={isSelected}
        onChange={(e) => onItemClick(id === "empty" ? "" : id)}
      />
      {isSelected ? <TickIcon /> : <EmptyCircle />}
      <ImageWithFallBack
        height={24}
        width={24}
        src={icon?.src}
        alt={icon?.alt}
      />
      <BodyText color={ThemeColor.BodyText}>{text}</BodyText>
      {!isNullish(numberOfAvailableMatches) && (
        <BodyText color={ThemeColor.LabelText}>
          ({numberOfAvailableMatches})
        </BodyText>
      )}
    </ItemLabel>
  );
};
