import { FontSize } from "@themeTypes/FontSizeType";
import { TeaserGrid } from "@ui/elements/TeaserGrid";
import { RelatedVideosWrapper } from "@ui/blocks/VideoPlayerRelatedVideos/elements/RelatedVideosWrapper";
import { FC } from "react";
import { VideoCard } from "@ui/blocks/VideoCard";
import { getCardUrl } from "@features/discovery/helpers/getCardUrl";
import { VideoCardData } from "@/shared/types/VideoCardType";
import { TitleText } from "@ui/elements/TitleText";

export interface RelatedVideosProps {
  sectionTitle: string;
  relatedVideosCards?: Array<VideoCardData>;
}

export const RelatedVideos: FC<RelatedVideosProps> = ({
  sectionTitle,
  relatedVideosCards,
}) => {
  const renderRelatedVideos = () => {
    return relatedVideosCards?.map((card) => (
      <VideoCard
        key={card.id ?? card.uiKey}
        imageObject={card.imageObject}
        providerName={card.providerName}
        providerLogo={card.providerLogo}
        title={card.title}
        url={getCardUrl(card)}
      />
    ));
  };

  if (relatedVideosCards?.length === 0) {
    return null;
  }
  return (
    <RelatedVideosWrapper>
      <TitleText
        fontWeight={700}
        size={FontSize.Title_6}
        style={{ marginBottom: 16 }}
      >
        {sectionTitle}
      </TitleText>
      <TeaserGrid>{renderRelatedVideos()}</TeaserGrid>
    </RelatedVideosWrapper>
  );
};
