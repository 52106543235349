import { useGetClipsDetailQuery } from "@features/clipsDetail/services/clipsDetailApi";
import { FC, useMemo, useState } from "react";
import { VideoPlayerRelatedVideos } from "@ui/blocks/VideoPlayerRelatedVideos";
import { isNullish } from "@/shared/helpers/isNullish";
import { useLocale } from "@/shared/hooks/useLocale";
import { VideoDetailPageWrapper } from "@ui/elements/VideoDetailPageWrapper";
import { useParams } from "react-router";
import { ScreenName } from "@features/tracking/screen-names";
import { usePageTracking } from "@features/tracking/hooks/usePageTracking";
import { useScreenName } from "@features/tracking/hooks/useScreenName";
import { LogoSpinnerCentered } from "@ui/blocks/LoadingIcon/LogoSpinnerCentered";
import { LogoLoadingSpinner } from "@ui/blocks/LoadingIcon/LoadinIcon";
import { VideoJSPlayer } from "@ui/blocks/VideoPlayer/types/VideoJSPlayer";
import { sendVideoPlayedEvent } from "@features/tracking/services/sendVideoPlayedEvent";
import { useFullScreen } from "@/shared/hooks/useFullScreen";

interface ClipsDetailPageProps {
  match?: { params: { id: string } };
}

export const ClipsDetailPage: FC<ClipsDetailPageProps> = (props) => {
  const params = useParams();
  const { t, locale } = useLocale();
  const fullScreenRef = useFullScreen();
  const { data, isLoading } = useGetClipsDetailQuery({
    locale,
    clipID: params.id ?? "1",
  });

  useScreenName(ScreenName.ClipsDetail);
  usePageTracking();

  const [isAdBlockerActive, setIsAdBlockerActive] = useState(false);

  // Fix when adblocker strategy is known
  const handleAdBlocker = () => {
    setIsAdBlockerActive(true);
  };

  const VideoSection = useMemo(() => {
    if (isNullish(data)) {
      return null;
    }

    const handleVideoPlayedEvent = (player: VideoJSPlayer) => {
      if (isNullish(data.videojsPlayer)) {
        return;
      }

      sendVideoPlayedEvent({
        duration: player.currentTime() ?? 0,
        fullScreenRef: fullScreenRef,
        player,
        wasFinished: true,
        options: {
          config: data.videojsPlayer.config,
          playlist: data.videojsPlayer.playlist,
        },
      });
    };

    return (
      <VideoPlayerRelatedVideos
        videoTitle={data.pageTitle}
        videoPublisher={data.publisherImprintLink}
        matchScoreData={null}
        relatedVideos={{
          sectionTitle: t("RELATED_VIDEOS"),
          relatedVideosCards: data.relatedVideos.items,
        }}
        videoPlayer={{
          ...data.videojsPlayer,
          onAdBlockerActive: handleAdBlocker,
          shouldShowAdBlocker: isAdBlockerActive,
          onVideoPlayedEvent: handleVideoPlayedEvent,
        }}
        videoProvider={{
          providerLogo: {
            src: data.providerLogo?.path,
            alt: data.providerLogo?.alt,
          },
          providerId: data.providerId,
          providerHandle: data.providerHandle,
          providerName: data.providerName,
          publishedTime: data.publishTime,
        }}
      />
    );
  }, [data, fullScreenRef, isAdBlockerActive, t]);

  // Handle page loader here
  if (isNullish(data) && isLoading) {
    return (
      <LogoSpinnerCentered data-testid={"loader"}>
        <LogoLoadingSpinner />
      </LogoSpinnerCentered>
    );
  }

  return <VideoDetailPageWrapper>{VideoSection}</VideoDetailPageWrapper>;
};
