import styled from "styled-components";

interface GridAreaProps {
  column: string;
  row: string;
}

export const GridArea = styled.div<GridAreaProps>`
  display: grid;
  grid-column: ${(props) => props.column};
  grid-row: ${(props) => props.row};
`;
