import { createGlobalStyle } from "styled-components";

interface BodyScrollBlockerProps {
  shouldBlockScroll: boolean;
}

export const BodyScrollBlocker = createGlobalStyle<BodyScrollBlockerProps>`
    body {
        overflow: ${({ shouldBlockScroll }) => (shouldBlockScroll ? "hidden" : "scroll")}
    }
`;
