import { TitleText } from "@ui/elements/TitleText";
import { BodyText } from "@ui/elements/BodyText";
import { ThemeColor } from "@themeTypes/ThemeColorType";
import { FontSize } from "@themeTypes/FontSizeType";
import { FC } from "react";
import {
  ImageWithFallBack,
  ImageWithFallBackProps,
} from "@ui/blocks/ImageWithFallBack";
import { isNullish } from "@/shared/helpers/isNullish";
import { EmptyStateWrapper } from "@ui/blocks/EmptyState/elements/EmptyStateWrapper";

export interface EmptyStateProps {
  message: string;
  title: string;
  image?: ImageWithFallBackProps;
}

export const EmptyState: FC<EmptyStateProps> = (props) => {
  const { title, message, image } = props;

  return (
    <EmptyStateWrapper>
      <TitleText color={ThemeColor.BodyText} size={FontSize.Title_3}>
        {title}
      </TitleText>
      <BodyText color={ThemeColor.BodyText}>{message}</BodyText>
      {!isNullish(image) && <ImageWithFallBack {...image}></ImageWithFallBack>}
    </EmptyStateWrapper>
  );
};
