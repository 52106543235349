import { OFTV_URL, ONEFOOTBALL_URL } from "@/shared/constants/Routes";

interface CreateCheckoutUrlProps {
  locale: string;
  matchId: string;
}

export const createCheckoutUrl = ({
  locale,
  matchId,
}: CreateCheckoutUrlProps): string =>
  `${ONEFOOTBALL_URL}/${locale}/checkout/match/${matchId}?redirectUrl=${OFTV_URL}/live-matches/${matchId}`;
