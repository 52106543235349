import { createBrowserRouter } from "react-router-dom";
import { AppRoutes } from "@/shared/constants/Routes";
import { DiscoveryPage } from "@/pages/Discovery/DiscoveryPage";
import { PageLayoutContainer } from "@features/pageLayout/containers/PageLayoutContainer";
import { ClipsPage } from "@/pages/Clips/ClipsPage";
import { ErrorPage } from "@/pages/ErrorPage/ErrorPage";
import { LiveMatchesPage } from "@/pages/LiveMatches/LiveMatchesPage";
import { ClipsDetailPage } from "@/pages/ClipsDetail/ClipsDetailPage";
import { CreatorDetailPage } from "@/pages/CreatorDetail/CreatorDetailPage";
import { createMemoryRouter, RouteObject } from "react-router";
import { LiveMatchDetailsPage } from "@/pages/LiveMatchDetail/LiveMatchDetailsPage";
import { Page404 } from "@/pages/404";

interface GetMemoryRouterProps {
  routes?: Array<RouteObject>;
  initialEntries?: Array<string>;
  initialIndex?: number;
}

/**
 * Checks if the creator page should be rendered based on the feature flag.
 *
 * TODO: Remove this flag once the creator page design is validated.
 * https://onefootball.atlassian.net/browse/PN-714
 */
const isCreatorPageFeatureEnabled =
  import.meta.env.VITE_CREATOR_PAGE_FEATURE_ENABLED === "true";

const appRoutes = [
  {
    element: <PageLayoutContainer />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
      {
        path: AppRoutes.Home,
        element: <DiscoveryPage />,
      },
      {
        path: AppRoutes.Highlights,
        element: <ClipsPage />,
      },
      {
        path: AppRoutes.Error,
        element: <ErrorPage />,
      },
      {
        path: AppRoutes.LiveMatches,
        children: [
          {
            index: true,
            element: <LiveMatchesPage />,
          },
          {
            path: AppRoutes.MatchDetail,
            element: <LiveMatchDetailsPage />,
          },
        ],
      },
      {
        path: AppRoutes.Clips,
        children: [
          {
            index: true,
            element: <ClipsPage />,
          },
          {
            path: AppRoutes.ClipsDetail,
            element: <ClipsDetailPage />,
          },
        ],
      },
      ...(isCreatorPageFeatureEnabled
        ? [
            {
              path: `${AppRoutes.Creators}/${AppRoutes.CreatorDetail}`,
              element: <CreatorDetailPage />,
            },
          ]
        : []),
    ],
  },
];

export const getMemoryRouter = ({
  routes = appRoutes,
  initialEntries = ["/"],
  initialIndex = 0,
}: GetMemoryRouterProps): ReturnType<typeof createMemoryRouter> =>
  createMemoryRouter(routes, { initialEntries, initialIndex });

export const appRouter = createBrowserRouter(appRoutes);
