import styled from "styled-components";
import { deviceSizeQuery } from "@/shared/constants/deviceSizeQuery";
import { limit } from "@ui/elements/GlobalStyles/helpers/limit";

export const LayoutGrid = styled.div`
  display: grid;
  ${limit};
  gap: 1.5rem;
  padding: 2.5rem 0;
  width: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto 1fr;

  @media all and ${deviceSizeQuery.tabletLandscape} {
    grid-template-columns: 1fr 20rem;
    gap: 2rem;
    padding: 5rem 0;
    margin: 0 auto;
  }
  @media all and ${deviceSizeQuery.desktop} {
    grid-template-columns: 1fr 30rem;
  }
`;
