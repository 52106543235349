import React, { FC, useState } from "react";
import { FiltersContainer } from "@features/liveMatches/containers/FiltersContainer";
import { LiveMatchCardListContainer } from "@features/liveMatches/containers/LiveMatchesCardListContainer";
import { useAppSelector } from "@/shared/hooks/appHooks";
import { BurgerMenu } from "@ui/blocks/BurgerMenu";
import { selectIsDarkTheme } from "@features/pageLayout/store/pageLayoutSlice";
import filtersIconLight from "/assets/icons/filter_light.svg";
import filtersIconDark from "/assets/icons/filter_dark.svg";
import { ScreenName } from "@features/tracking/screen-names";
import { usePageTracking } from "@features/tracking/hooks/usePageTracking";
import { useScreenName } from "@features/tracking/hooks/useScreenName";
import { FontSize } from "@themeTypes/FontSizeType";
import { useLocale } from "@/shared/hooks/useLocale";
import { Title1 } from "@features/liveMatches/elements/Title1";
import { Title2 } from "@features/liveMatches/elements/Title2";
import { LayoutGrid } from "@ui/elements/LayoutGrid";
import { GridArea } from "@ui/elements/GridArea";
import { ScrollToTopButton } from "@ui/blocks/ScrollToTopButton";

const LiveMatchesPage: FC = () => {
  const [filtersDrawerOpened, setFiltersDrawerOpened] = useState(false);
  const { t } = useLocale();
  useScreenName(ScreenName.LiveMatches);
  usePageTracking();

  const isDarkTheme = useAppSelector(selectIsDarkTheme);

  return (
    <div style={{ position: "relative" }}>
      <LayoutGrid data-testid="live-matches-container">
        <BurgerMenu
          onBurgerClick={() => setFiltersDrawerOpened(!filtersDrawerOpened)}
          iconSrc={isDarkTheme ? filtersIconDark : filtersIconLight}
          iconAlt="filters-icon"
          absolutePosition={{ right: "1.25rem", top: "2.5rem", left: "auto" }}
        />
        <ScrollToTopButton
          isDarkMode={isDarkTheme}
          message={t("BACK_TO_TOP")}
          scrollToTopThreshHold={500}
        ></ScrollToTopButton>
        <GridArea column={"1/2"} row={"1/2"}>
          <Title1 size={FontSize.DrukTitle_4}>{t("LIVE_MATCHES")}</Title1>
        </GridArea>

        <GridArea column={"1/2"} row={"2/3"}>
          <LiveMatchCardListContainer />
        </GridArea>

        <GridArea column={"2/3"} row={"1/2"}>
          <Title2 size={FontSize.Title_5}>{t("FILTERS")}</Title2>
        </GridArea>

        <GridArea column={"2/3"} row={"2/3"}>
          <FiltersContainer
            filtersDrawerOpened={filtersDrawerOpened}
            onFiltersDrawerOutsideClick={() => setFiltersDrawerOpened(false)}
          />
        </GridArea>
      </LayoutGrid>
    </div>
  );
};

export { LiveMatchesPage };
