import React, { FC } from "react";
import { MatchPlayerContainer } from "@features/matchDetail/containers/MatchPlayerContainer";
import { RelatedMatchesContainer } from "@features/matchDetail/containers/RelatedMatchesContainer";
import { WidgetsContainer } from "@features/matchDetail/containers/WidgetsContainer";
import { VideoDetailPageWrapper } from "@ui/elements/VideoDetailPageWrapper";
import { MatchInfoWidgetWrapper } from "@ui/elements/MatchInfoWidgetWrapper";

export const LiveMatchDetailsPage: FC = () => {
  return (
    <VideoDetailPageWrapper>
      <MatchInfoWidgetWrapper>
        <MatchPlayerContainer />
        <WidgetsContainer />
        <RelatedMatchesContainer />
      </MatchInfoWidgetWrapper>
    </VideoDetailPageWrapper>
  );
};
