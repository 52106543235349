import {
  type,
  string,
  array,
  boolean,
  number,
  Infer,
  partial,
} from "superstruct";

const PartnerStruct = type({
  id: string(),
  name: string(),
  image_url: string(),
});

const StreamStruct = type({
  id: string(),
  commentary_language: string(),
});

const StreamMetaStruct = type({
  state: string(),
  platforms: array(string()),
  build_up_minutes: number(),
  wrap_up_minutes: number(),
  can_screencast: boolean(),
  rights_id: number(),
});

const OfferStruct = type({
  id: string(),
  store_id: string(),
  type: string(),
  title: string(),
  description: string(),
  price_web: string(),
  image_url: string(),
  is_free: boolean(),
  has_purchased: boolean(),
  most_popular: boolean(),
});

const StreamingOptionStruct = type({
  partner: PartnerStruct,
  streams: array(StreamStruct),
  stream_meta: StreamMetaStruct,
  offers: array(OfferStruct),
});

const MetadataStruct = partial(
  type({
    match_id: string(),
    home_id: string(),
    away_id: string(),
    competition_id: string(),
    refresh_at: string(),
  }),
);

export const StreamingOptionsStruct = type({
  streaming_options: array(StreamingOptionStruct),
  metadata: MetadataStruct,
});

export type StreamingOptionsDTO = Infer<typeof StreamingOptionsStruct>;

const ErrorStruct = type({
  title: string(),
  message: string(),
  type: string(),
});

export type ErrorDTO = Infer<typeof ErrorStruct>;
