import styled from "styled-components";
import { WithThemeType } from "@themeTypes/WithThemeType";

export const Wrapper = styled.span<WithThemeType>`
  display: flex;
  width: 1rem;
  height: 1rem;
  place-content: center;
  color: ${(p) => p.theme.colors.elevatedBackground};
  background-color: ${(p) => p.theme.colors.bodyText};
  border-radius: 100%;
`;
