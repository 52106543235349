import styled from "styled-components";
import { deviceSizeQuery } from "@/shared/constants/deviceSizeQuery";

export const MatchInfoWidgetWrapper = styled.section`
  display: grid;
  grid-gap: 2rem;
  @media all and ${deviceSizeQuery.tabletLandscape} {
    grid-template-columns: 2fr 1fr;
  }
`;
