import { FC, JSX, useMemo, useReducer } from "react";
import { HeaderList } from "@ui/blocks/HeaderTop/elements/HeaderList";
import { ImageWithFallBackProps } from "@ui/blocks/ImageWithFallBack";
import { NavLinks } from "@ui/blocks/HeaderTop/elements/NavLinks";
import { TextScreenReader } from "@ui/elements/TextScreenReader";
import { useLocale } from "@/shared/hooks/useLocale";
import { useLocation } from "react-router-dom";
import { ProfileSection } from "@ui/blocks/HeaderTop/elements/ProfileSection";
import { Button, ButtonVariant } from "@ui/elements/Button";
import { TitleText } from "@ui/elements/TitleText";
import { ProfileIcon } from "@ui/blocks/HeaderTop/elements/ProfileIcon";
import { ProfileButton } from "@ui/blocks/HeaderTop/elements/ProfileButton";
import { ProfileButtonMobile } from "@ui/blocks/HeaderTop/elements/ProfileButtonMobile";
import { AppRoutes, ExternalRoutes } from "@/shared/constants/Routes";
import { HeaderWrapper } from "@ui/blocks/HeaderTop/elements/HeaderWrapper";
import { ScreenName, ScreenNameType } from "@features/tracking/screen-names";
import { HeaderLinks } from "@ui/blocks/HeaderTop/constants/HeaderLinks";
import { HeaderNavItem } from "@ui/blocks/HeaderTop/blocks/HeaderNavItem";
import { isActiveUrl } from "@ui/blocks/HeaderTop/helpers/isActiveUrl";
import { Logo } from "@ui/blocks/HeaderTop/blocks/Logo";
import { ProfileSvg } from "@ui/blocks/HeaderTop/elements/ProfileSvg";
import { OFcomButtonList } from "@ui/blocks/HeaderTop/elements/OFcomButton";
import { DropDownMenu, DropDownMenuProps } from "@ui/blocks/DropDownMenu";
import { isNullish } from "@/shared/helpers/isNullish";
import { ToggleSwitch } from "@ui/blocks/ToggleSwitch";
import { getMenuAssets } from "@features/pageLayout/helpers/getMenuAssets";
import { ToggleSwitchList } from "@ui/blocks/HeaderTop/elements/ToggleSwitchList";

export interface HeaderTopProps {
  imageHeader: ImageWithFallBackProps;
  onSendTrackingEvent: (screenName: ScreenNameType) => void;
  isLoggedIn: boolean;
  onChangeTheme: () => void;
  isDarkMode?: boolean;
  children?: JSX.Element;
  languageMenu?: DropDownMenuProps;
  themeMenu?: DropDownMenuProps;
}

export const HeaderTop: FC<HeaderTopProps> = ({
  imageHeader,
  isLoggedIn,
  isDarkMode = false,
  onSendTrackingEvent,
  themeMenu,
  languageMenu,
  onChangeTheme,
}) => {
  const [isMenuOpen, toogleMenuOpen] = useReducer(
    (state: boolean): boolean => !state,
    false,
  );
  const { t, locale } = useLocale();
  const { pathname } = useLocation();

  // we use window.location.href because we need an absolute path
  const LoginUrl = `${ExternalRoutes.LoginPage(locale)}/?redirect=${window.location.href}`;
  const SignUpUrl = `${ExternalRoutes.SignUnPage(locale)}/?redirect=${window.location.href}`;

  const MemoisedHeaderLinks = useMemo(
    () =>
      HeaderLinks.map((element, i) => (
        <HeaderNavItem
          key={i}
          to={element.to}
          isActive={isActiveUrl(element.to, pathname)}
          onNavItemClick={() => {
            toogleMenuOpen();
            onSendTrackingEvent(element.screenName);
          }}
          isDarkMode={isDarkMode}
          text={t(element.textKey)}
        />
      )),
    [isDarkMode, onSendTrackingEvent, pathname, t],
  );

  return (
    <HeaderWrapper>
      <HeaderList>
        <Logo
          isMenuOpen={isMenuOpen}
          onMobileMenuClick={toogleMenuOpen}
          screenReaderText={{
            menuClose: t("NAVIGATION_CLOSE_MENU"),
            menuOpen: t("NAVIGATION_OPEN_MENU"),
            navigationTo: t("NAVIGATION_HOME"),
          }}
          linkTo={AppRoutes.Home}
          logoImage={{ ...imageHeader }}
          isDarkMode={isDarkMode}
        />
        <NavLinks isMobileVisible={isMenuOpen}>
          {MemoisedHeaderLinks}
          <OFcomButtonList>
            {!isNullish(languageMenu) && (
              <DropDownMenu {...languageMenu} currentLang={locale} />
            )}
          </OFcomButtonList>
          <ToggleSwitchList>
            <ToggleSwitch
              onToggleChange={onChangeTheme}
              isChecked={isDarkMode}
              sliderIcon={getMenuAssets(isDarkMode).toggleButtonIcon}
            />
          </ToggleSwitchList>
        </NavLinks>
        {isLoggedIn ? (
          <ProfileSection>
            <Button
              variant={"icon"}
              as="a"
              href={ExternalRoutes.ProfilePage(locale)}
              target={"_blank"}
              onClick={() => onSendTrackingEvent(ScreenName.Profile)}
            >
              <ProfileIcon>{ProfileSvg}</ProfileIcon>
              <TextScreenReader>{t("NAVIGATION_PROFILE")}</TextScreenReader>
            </Button>
          </ProfileSection>
        ) : (
          <ProfileSection>
            <ProfileButton
              variant={ButtonVariant.outline}
              as="a"
              href={LoginUrl}
              target={"_blank"}
              onClick={() => onSendTrackingEvent(ScreenName.Login)}
            >
              <TitleText>{t("NAVIGATION_LOGIN")}</TitleText>
            </ProfileButton>
            <ProfileButton
              variant={ButtonVariant.fill}
              as="a"
              href={SignUpUrl}
              target={"_blank"}
              onClick={() => onSendTrackingEvent(ScreenName.Register)}
            >
              <TitleText>{t("NAVIGATION_SIGN_UP")}</TitleText>
            </ProfileButton>

            <ProfileButtonMobile
              variant={"icon"}
              as="a"
              href={LoginUrl}
              target={"_blank"}
              onClick={() => onSendTrackingEvent(ScreenName.Login)}
            >
              <ProfileIcon>{ProfileSvg}</ProfileIcon>
              <TextScreenReader>{t("NAVIGATION_LOGIN")}</TextScreenReader>
            </ProfileButtonMobile>
          </ProfileSection>
        )}
      </HeaderList>
    </HeaderWrapper>
  );
};
