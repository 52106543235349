import { SectionWrapper } from "@ui/blocks/MatchInfoWidget/elements/SectionWrapper";
import { TitleText } from "@ui/elements/TitleText";
import { TeamsInfoWrapper } from "@ui/blocks/MatchInfoWidget/elements/TeamsInfoWrapper";
import { TitleDruk } from "@ui/elements/TitleDruk";
import { FontSize } from "@themeTypes/FontSizeType";
import { BodyText } from "@ui/elements/BodyText";
import { WidgetWrapper } from "@ui/blocks/MatchInfoWidget/elements/WidgetWrapper";
import Skeleton from "react-loading-skeleton";
import { FC } from "react";

export const WidgetLoader: FC = () => {
  return (
    <WidgetWrapper data-testid="widget-loader">
      <SectionWrapper>
        <TitleText>
          <Skeleton width="9.5rem" />
        </TitleText>
        <Skeleton height={32} width={32} />
      </SectionWrapper>

      <TeamsInfoWrapper>
        <SectionWrapper>
          <Skeleton height={48} width={48} />
          <TitleDruk>
            <Skeleton width="3.125rem" />
          </TitleDruk>
        </SectionWrapper>
        <SectionWrapper>
          <TitleDruk size={FontSize.Title_2}>
            <Skeleton width="3.125rem" />
          </TitleDruk>
          <BodyText>
            <Skeleton width="8.75rem" />
          </BodyText>
        </SectionWrapper>
        <SectionWrapper>
          <Skeleton height={48} width={48} />
          <TitleDruk>
            <Skeleton width="3.125rem" />
          </TitleDruk>
        </SectionWrapper>
      </TeamsInfoWrapper>
      <SectionWrapper>
        <BodyText size={FontSize.BodyText_7}>
          <Skeleton width="5rem" />
        </BodyText>
        <BodyText size={FontSize.BodyText_7}>
          <Skeleton width="3.125rem" />
        </BodyText>
        <BodyText size={FontSize.BodyText_7}>
          <Skeleton width="7rem" />
        </BodyText>
      </SectionWrapper>
    </WidgetWrapper>
  );
};
