import { parseYouboraValues } from "@ui/blocks/VideoPlayer/helpers/parseYouboraConfigValues";
import { GoogleAdsManagerResponse } from "@ui/blocks/VideoPlayer/types/GoogleAdsManagerResponse";
import { sendImaTrackingEvents } from "@ui/blocks/VideoPlayer/services/sendAdTrackingEvent";
import { isNullish } from "@/shared/helpers/isNullish";
import { VideoJSPlayer } from "@ui/blocks/VideoPlayer/types/VideoJSPlayer";
import type { google } from "@alugha/ima/lib/typings/ima";
import { YouboraConfigType } from "@ui/blocks/VideoPlayer/types/YouboraConfigType";
import videojsNPAWAdapter from "@ui/blocks/VideoPlayer/config/videojsNPAWAdapter.json";
import googleIMAAdapter from "@ui/blocks/VideoPlayer/config/googleIMAAdapter.json";

interface ConfigVideoAnalyticsProps {
  player: VideoJSPlayer;
  config: YouboraConfigType;
  npawAccountCode: string;
}

export const configVideoAnalytics = async ({
  player,
  config,
  npawAccountCode,
}: ConfigVideoAnalyticsProps): Promise<void> => {
  if (typeof window === "undefined") {
    return;
  }

  try {
    const NpawPlugin = await import("npaw-plugin");
    const parsed = parseYouboraValues(config);
    const npawPluginInstance = new NpawPlugin.default(npawAccountCode);

    // As per instruction from NPAW support, we need to host JSON config for adapters locally
    npawPluginInstance.registerAdapterFromString(
      player,
      JSON.stringify(videojsNPAWAdapter),
    );

    npawPluginInstance.setAnalyticsOptions(parsed);

    player.one("ads-manager", ({ adsManager }: GoogleAdsManagerResponse) => {
      npawPluginInstance.registerAdsAdapterFromString(
        adsManager,
        JSON.stringify(googleIMAAdapter),
      );

      if (isNullish(player.ima)) {
        return;
      }

      //@ts-expect-error: @alugha/ima exports enum only as type "start", we need it as value also since ts types are used only during build time
      adsManager.addEventListener("start", (event: google.ima.AdEvent) => {
        sendImaTrackingEvents({ player, event });
      });

      // Handle skipping ads without videojs-ima library
      //@ts-expect-error: @alugha/ima exports enum only as type "skip", we need it as value also since ts types are used only during build time
      adsManager.addEventListener("skip", () => {
        player.autoplay(true);
        void player.play();
      });
    });
  } catch (e) {
    // Error initialising NPAW
  }
};
