import React, { FC, useState } from "react";
import { useRefinementList } from "react-instantsearch";
import { SingleFilterWrapper } from "@ui/elements/SingleFilterWrapper";
import { SingleFilterListItems } from "@features/refinementListFilters/elements/SingleFilterListItems";
import { Input } from "@features/refinementListFilters/elements/Input";
import { TickIcon } from "@ui/blocks/TickIcon";
import { EmptyCircle } from "@ui/blocks/CompetitionsFilterItem/elements/EmptyCircle";
import { BodyText } from "@ui/elements/BodyText";
import { ThemeColor } from "@themeTypes/ThemeColorType";
import { FilterList } from "@ui/elements/FilterList";
import { SingleFilterTitle } from "@ui/blocks/SingleFilterTitle";
import { isNullish } from "@/shared/helpers/isNullish";

export const AtrributeOptions = {
  competitions: "competitions",
  teams: "teams",
  players: "players",
} as const;

export interface RefinementListFiltersProps {
  attribute: (typeof AtrributeOptions)[keyof typeof AtrributeOptions];
  title: string;
  isDarkTheme: boolean;
  onItemClicked?: () => void;
}

export const RefinementListFilters: FC<RefinementListFiltersProps> = (
  props,
) => {
  const { attribute, title, isDarkTheme, onItemClicked } = props;
  const { items, refine } = useRefinementList({
    attribute: attribute,
  });

  const sortedElements = items.sort((a, b) => a.label.localeCompare(b.label));
  const [collapsed, setCollapsed] = useState(false);

  return (
    <SingleFilterWrapper collapsed={collapsed}>
      <SingleFilterTitle
        title={title}
        isDarkTheme={isDarkTheme}
        onTitleClick={() => {
          setCollapsed(!collapsed);
          if (!isNullish(onItemClicked)) {
            onItemClicked();
          }
        }}
        collapsed={collapsed}
      />
      <div style={{ overflow: "scroll", marginTop: "0.5rem" }}>
        <FilterList>
          {sortedElements.map((item) => {
            return (
              <li key={item.label}>
                <SingleFilterListItems key={item.label}>
                  <Input
                    type="checkbox"
                    checked={item.isRefined}
                    onChange={(event) => refine(item.value)}
                  />
                  {item.isRefined ? (
                    <TickIcon />
                  ) : (
                    <EmptyCircle data-testid={"empty-circle"} />
                  )}
                  <BodyText color={ThemeColor.BodyText}>{item.label}</BodyText>
                  <BodyText color={ThemeColor.LabelText}>
                    ({item.count})
                  </BodyText>
                </SingleFilterListItems>
              </li>
            );
          })}
        </FilterList>
      </div>
    </SingleFilterWrapper>
  );
};
