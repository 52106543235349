import {
  CreatorDetailDTO,
  CreatorDetailDTOStruct,
} from "@features/creatorDetail/types/CreatorDetailDTO";
import {
  CreatorVideosDTO,
  CreatorVideosDTOStruct,
} from "@features/creatorDetail/types/CreatorVideosDTO";
import { webExperienceApi } from "@/shared/services/webExperienceApi";
import { create } from "superstruct";

interface GetCreatorDetailApiProps {
  locale: string;
  providerIdOrHandle: string;
}

interface GetCreatorVideosApiProps {
  locale: string;
  providerIdOrHandle: string;
  page: number;
}

const SORTING = "latest";
const PAGE_SIZE = 30;

export const creatorDetailApi = webExperienceApi.injectEndpoints({
  endpoints: (builder) => ({
    getCreatorDetail: builder.query<CreatorDetailDTO, GetCreatorDetailApiProps>(
      {
        query: ({ locale, providerIdOrHandle }) => ({
          url: `${locale}/tv-hub/providers/${providerIdOrHandle}`,
        }),
        transformResponse: (response) => {
          return create(response, CreatorDetailDTOStruct, "can not create");
        },
      },
    ),
    getCreatorVideos: builder.query<CreatorVideosDTO, GetCreatorVideosApiProps>(
      {
        query: ({ locale, providerIdOrHandle, page }) => ({
          url: `${locale}/tv-hub/providers/${providerIdOrHandle}/videos?page=${page}&size=${PAGE_SIZE}&sorting=${SORTING}`,
        }),
        transformResponse: (response) => {
          return create(response, CreatorVideosDTOStruct, "can not create");
        },
      },
    ),
  }),
});

export const { useGetCreatorDetailQuery, useGetCreatorVideosQuery } =
  creatorDetailApi;
