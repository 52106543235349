/**
 * Formats the given ISO date string as a human-readable "time ago" format.
 *
 * @param {string} dateISOString - The ISO date string to be formatted.
 * @param {string} [locale="en"] - The locale code to format the time string.
 *
 * @returns {string} - A string representing the time difference between the given date and now, localized based on the provided locale.
 */
export const timeAgo = (dateISOString: string, locale = "en"): string => {
  const date = new Date(dateISOString);
  const now = new Date();

  const secondsAgo = Math.floor((now.getTime() - date.getTime()) / 1000);

  const rtf = new Intl.RelativeTimeFormat(locale, { numeric: "auto" });

  if (secondsAgo < 60) {
    return rtf.format(-secondsAgo, "second");
  }

  const minutesAgo = Math.floor(secondsAgo / 60);
  if (minutesAgo < 60) {
    return rtf.format(-minutesAgo, "minute");
  }

  const hoursAgo = Math.floor(minutesAgo / 60);
  if (hoursAgo < 24) {
    return rtf.format(-hoursAgo, "hour");
  }

  const daysAgo = Math.floor(hoursAgo / 24);
  if (daysAgo < 7) {
    return rtf.format(-daysAgo, "day");
  }

  const weeksAgo = Math.floor(daysAgo / 7);
  if (weeksAgo < 4) {
    return rtf.format(-weeksAgo, "week");
  }

  const monthsAgo = Math.floor(daysAgo / 30);
  if (monthsAgo < 12) {
    return rtf.format(-monthsAgo, "month");
  }

  const yearsAgo = Math.floor(daysAgo / 365);
  return rtf.format(-yearsAgo, "year");
};
