import React, { FC } from "react";
import { isNullish } from "@/shared/helpers/isNullish";
import { LiveMatchCard } from "@ui/blocks/LiveMatchCard";
import { LiveMatchCardType } from "@/shared/types/LiveMatchCardType";

interface OnCardClickProps {
  price: string | undefined;
  itemId: string;
}

interface RelatedMatchesProps {
  items: Array<LiveMatchCardType>;
  currentMatchId: string;
  onCardClick: ({ price, itemId }: OnCardClickProps) => void;
}

export const RelatedMatches: FC<RelatedMatchesProps> = ({
  currentMatchId,
  items,
  onCardClick,
}) => {
  return items.slice(0, 7).map((item, i) => {
    if (item.matchId === currentMatchId) {
      return null;
    }
    const onClickEventProperties = {
      price: isNullish(item.price?.value)
        ? undefined
        : String(item.price.value),
      itemId: item.matchId,
    };
    return (
      <LiveMatchCard
        card={item}
        key={item.uiKey}
        onClick={() => {
          onCardClick(onClickEventProperties);
        }}
      />
    );
  });
};
