import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

interface UseLocaleReturnType {
  t: TFunction;
  locale: string;
}

export const useLocale = (): UseLocaleReturnType => {
  const { i18n } = useTranslation();
  const { t } = i18n;
  const locale = i18n.resolvedLanguage ?? i18n.language;
  return { t, locale };
};
