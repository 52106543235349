import type { FC } from "react";
import { useEffect, useState } from "react";
import { ScrollToTopButtonArrow } from "@ui/blocks/ScrollToTopButton/elements/ScrollToTopButtonArrow";
import { ScrollToTopButtonWrapper } from "@ui/blocks/ScrollToTopButton/elements/ScrollToTopButtonWrapper";
import { ImageWithFallBack } from "@ui/blocks/ImageWithFallBack";
import { TitleText } from "@ui/elements/TitleText";

export interface ScrollToTopButtonProps {
  message: string;
  scrollToTopThreshHold?: number;
  isDarkMode?: boolean;
}

export const ScrollToTopButton: FC<ScrollToTopButtonProps> = (props) => {
  const { scrollToTopThreshHold = 300, message, isDarkMode } = props;

  const [isVisible, setIsVisible] = useState<boolean>(false);

  const scrollToTop = () => {
    window.scroll({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= scrollToTopThreshHold) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollToTopThreshHold]);

  return isVisible ? (
    <ScrollToTopButtonWrapper
      variant={"fill"}
      onClick={() => {
        scrollToTop();
      }}
    >
      <TitleText>{message}</TitleText>
      <ScrollToTopButtonArrow>
        <ImageWithFallBack
          isDarkMode={isDarkMode}
          width={24}
          height={24}
          alt=""
          src="https://images.onefootball.com/cw/icons/arrow-right-dark.svg"
          darkModeSrc="https://images.onefootball.com/cw/icons/arrow-right-light.svg"
        />
      </ScrollToTopButtonArrow>
    </ScrollToTopButtonWrapper>
  ) : null;
};
