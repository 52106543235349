import { appRouter } from "@/router";
import { RouterProvider } from "react-router-dom";
import React, { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@/shared/hooks/appHooks";
import { useInitTracking } from "@features/tracking/hooks/useInitTracking";
import { useGetLocationQuery } from "@features/tracking/services/geolocationApi";
import {
  selectIsDarkTheme,
  toggleDarkTheme,
} from "@features/pageLayout/store/pageLayoutSlice";
import { authenticate } from "@features/authentication";
import { checkAuthCookie } from "@features/authentication/checkAuthCookie";
import isPropValid from "@emotion/is-prop-valid";
import { darkTheme, defaultTheme } from "@/shared/themes";
import { GlobalStyles } from "@ui/elements/GlobalStyles";
import { StyleSheetManager, ThemeProvider } from "styled-components";
import { SkeletonTheme } from "react-loading-skeleton";
import { ErrorBoundaryContainer } from "@features/errorBoundary/containers/ErrorBoundaryContainer";
import { getSkeletonsConfig } from "@/utils/getSkeletonsConfig";
import { useObservability } from "@/modules/observability/hooks/useObservability";

export const App: FC = () => {
  const dispatch = useAppDispatch();
  const isDarkMode = useAppSelector(selectIsDarkTheme);
  useObservability();
  useInitTracking();
  useGetLocationQuery();

  const skeletonsConfig = getSkeletonsConfig(
    isDarkMode ? darkTheme : defaultTheme,
  );

  useEffect(() => {
    const isDark = localStorage.getItem("isDarkTheme");
    if (isDark !== null) {
      dispatch(toggleDarkTheme(JSON.parse(isDark) as boolean));
    }

    dispatch(authenticate(checkAuthCookie()));
  }, [dispatch]);

  return (
    <StyleSheetManager shouldForwardProp={isPropValid} enableVendorPrefixes>
      <ThemeProvider theme={isDarkMode ? darkTheme : defaultTheme}>
        <SkeletonTheme
          baseColor={skeletonsConfig.baseColor}
          highlightColor={skeletonsConfig.highlightColor}
        >
          <GlobalStyles />
          <ErrorBoundaryContainer>
            <RouterProvider router={appRouter} />
          </ErrorBoundaryContainer>
        </SkeletonTheme>
      </ThemeProvider>
    </StyleSheetManager>
  );
};
