import { FC } from "react";
import { LiveMatchCardType } from "@/shared/types/LiveMatchCardType";
import { CardWrapper } from "@ui/elements/CardWrapper";
import { CardContentWrapper } from "@ui/elements/CardContentWrapper";
import { FontSize } from "@themeTypes/FontSizeType";
import { Link } from "react-router-dom";
import { CardContentTitle } from "@ui/elements/CardContentTitle";
import { CardImage } from "@ui/elements/CardImage";
import { CompetitionWrapper } from "@ui/blocks/LiveMatchCard/elements/CompetitionWrapper";
import { PartnerSectionWrapper } from "@ui/blocks/LiveMatchCard/elements/PartnerSectionWrapper";
import { CardPartnerNameText } from "@ui/elements/CardPartnerNameText";
import { PriceButton } from "@ui/blocks/LiveMatchCard/elements/PriceButton";
import { isNullish } from "@/shared/helpers/isNullish";
import { ImageWithFallBack } from "@ui/blocks/ImageWithFallBack";
import { useLocale } from "@/shared/hooks/useLocale";
import { AppRoutes } from "@/shared/constants/Routes";
import { BodyText } from "@ui/elements/BodyText";

export interface LiveMatchCardProps {
  card: LiveMatchCardType;
  onClick?: () => void;
}

export const LiveMatchCard: FC<LiveMatchCardProps> = ({ card, onClick }) => {
  const { t } = useLocale();

  const priceToShow = () => {
    if (!isNullish(card.isPurchased) && card.isPurchased) {
      return t(`PURCHASED`).toUpperCase();
    }

    return isNullish(card.price)
      ? t(`PRICE_FREE`).toUpperCase()
      : `${card.price.value} ${card.price.currency}`;
  };

  return (
    <CardWrapper
      as={Link}
      to={`${AppRoutes.LiveMatches}/${card.matchId}`}
      onClick={onClick}
    >
      <CardImage>
        <ImageWithFallBack
          src={card.ottThumbnail.path}
          alt={card.ottThumbnail.alt}
          width={430}
        />
      </CardImage>
      <CardContentWrapper>
        <CardContentTitle size={FontSize.Title_7}>
          {card.homeTeam.name} - {card.awayTeam.name}
        </CardContentTitle>
        <CompetitionWrapper>
          <ImageWithFallBack
            width={20}
            height={20}
            src={card.competitionLogo.path}
            alt={card.competitionLogo.alt}
          />
          <CardPartnerNameText size={FontSize.BodyText_8}>
            {card.competitionName}
          </CardPartnerNameText>
        </CompetitionWrapper>

        <BodyText size={FontSize.BodyText_8}>
          {card.kickoffFormatted} |&nbsp;
          {card.kickoffTimeFormatted}
        </BodyText>

        <PartnerSectionWrapper>
          <ImageWithFallBack
            width={20}
            height={20}
            src={card.partnerLogo.path}
            alt={card.partnerLogo.alt}
          />
          <CardPartnerNameText size={FontSize.BodyText_8}>
            {card.partnerName}
          </CardPartnerNameText>
          <PriceButton size={FontSize.BodyText_7}>{priceToShow()}</PriceButton>
        </PartnerSectionWrapper>
      </CardContentWrapper>
    </CardWrapper>
  );
};
