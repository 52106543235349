import styled from "styled-components";
import { WithThemeType } from "@themeTypes/WithThemeType";

export const WidgetWrapper = styled.div<WithThemeType>`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: ${(p) => p.theme.colors.navigationBackground};
  min-height: 10rem;
  padding: 1rem;
  border-radius: 1rem;
  gap: 0.5rem;
`;
