import { webExperienceApi } from "@/shared/services/webExperienceApi";
import {
  MatchInfoDTO,
  MatchInfoStruct,
} from "@features/matchDetail/types/MatchInfoDTO";
import { create } from "superstruct";
import {
  StreamingOptionsDTO,
  StreamingOptionsStruct,
} from "@features/matchDetail/types/StreamingOptionsDTO";
import {
  StreamDTO,
  StreamDTOStruct,
  StreamErrorData,
} from "@features/matchDetail/types/StreamsDTO";
import { isNullish } from "@/shared/helpers/isNullish";
import i18n from "i18next";

interface GetMatchDetailsProps {
  locale: string;
  matchId?: string;
}

interface GetStreamingOptionsProps {
  locale: string;
  matchId: string;
}

interface GetStreamProps {
  locale: string;
  streamId: string;
}

export const matchDetailsApi = webExperienceApi.injectEndpoints({
  endpoints: (builder) => ({
    getMatchInfo: builder.query<MatchInfoDTO, GetMatchDetailsProps>({
      query: ({ locale, matchId = "" }) => ({
        url: `${locale}/tv-hub/matches/${matchId}`,
      }),
      transformResponse: (response) => {
        return create(
          response,
          MatchInfoStruct,
          "Fail to create matchdetails v2",
        );
      },
    }),
    getStreamingOptions: builder.query<
      StreamingOptionsDTO,
      GetStreamingOptionsProps
    >({
      query: ({ locale, matchId }) => ({
        url: `${locale}/tv-hub/matches/${matchId}/watch`,
      }),
      transformResponse: (response) => {
        return create(
          response,
          StreamingOptionsStruct,
          "can not create streamingOptionsApi response",
        );
      },
    }),
    getStream: builder.query<StreamDTO, GetStreamProps>({
      query: ({ locale, streamId }) => ({
        url: `${locale}/tv-hub/streams/${streamId}`,
      }),
      transformResponse: (response) => {
        return create(
          response,
          StreamDTOStruct,
          "can not create Streams response",
        );
      },
      transformErrorResponse: (error): StreamErrorData => {
        const errResponse = create(
          error.data,
          StreamDTOStruct,
          "Can not create error",
        );
        if (isNullish(errResponse.streamError)) {
          return {
            title: i18n.t("UNKNOWN_ERROR"),
            message: i18n.t("CANT_GET_STREAM"),
            type: i18n.t("UNKNOWN"),
          };
        }

        return errResponse.streamError;
      },
    }),
  }),
});

export const {
  useGetMatchInfoQuery,
  useGetStreamingOptionsQuery,
  useGetStreamQuery,
} = matchDetailsApi;
