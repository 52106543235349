import styled from "styled-components";
import { TeaserPageWrapper } from "@ui/elements/TeaserPageWrapper";

interface VideoCardsTeaserWrapperProps {
  featured?: boolean;
}

export const VideoCardsTeaserWrapper = styled(
  TeaserPageWrapper,
)<VideoCardsTeaserWrapperProps>`
  overflow: visible;
  height: fit-content;
`;
