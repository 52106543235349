import styled, { css } from "styled-components";
import { WithThemeType } from "@themeTypes/WithThemeType";
import { deviceSizeQuery } from "@/shared/constants/deviceSizeQuery";
import { hex2rgb } from "@/shared/helpers/hex2rgb";
import { ZIndexes } from "@/shared/constants/zIndexes";

interface FiltersWrapperProps extends WithThemeType {
  opened: boolean;
}

export const FiltersWrapper = styled.div<FiltersWrapperProps>`
  position: absolute;
  height: calc(100vh - 3.4375rem);
  top: 0;
  max-height: ${({ opened }) => (opened ? "100%" : "0")};
  right: 0;
  display: block;
  background: ${({ theme }) => theme.colors.background};
  padding: ${({ opened }) => (opened ? "1rem" : "0")};
  width: 100%;
  z-index: ${ZIndexes.Above};
  overflow: scroll;
  ${({ theme }) => {
    const { r, g, b } = hex2rgb(theme.colors.bodyText);
    return css`
      border-left: 1px solid rgba(${r}, ${g}, ${b}, 0.1);
      border-bottom: 1px solid rgba(${r}, ${g}, ${b}, 0.1);
      border-radius: ${theme.roundedCorners.regular};
    `;
  }}
  @media all and ${deviceSizeQuery.tabletLandscape} {
    position: static;
    margin-bottom: 5rem;
    border: none;
    padding: 0;
    max-height: 100%;
    overflow: hidden;
  }
`;
