import { Infer, string, type, number, array, boolean } from "superstruct";

export const CreatorVideosDTOStruct = type({
  metadata: type({
    next_page: string(),
    prev_page: string(),
  }),
  data: array(
    type({
      id: string(),
      content_type: string(),
      title: string(),
      thumbnail_url: string(),
      provider: type({
        id: string(),
        name: string(),
        thumbnail_url: string(),
        is_verified: boolean(),
      }),
      published_since: string(),
      tracking_parameters: type({
        item_id: string(),
        curation_type: string(),
        item_type: string(),
        provider_id: string(),
        index_name: string(),
        tab_id: string(),
        query_id: string(),
        tab_ids_order: string(),
      }),
      share_url: string(),
      style: type({
        orientation: string(),
      }),
      duration_in_seconds: number(),
    }),
  ),
});

export type CreatorVideosDTO = Infer<typeof CreatorVideosDTOStruct>;
