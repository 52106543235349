import { Infer, number, optional, string, type, union } from "superstruct";
import { XPAImageObjectStruct } from "@/shared/types/XPAImageObjectType";

export const StadiumDataTypeStruct = type({
  id: union([string(), number()]),
  city: string(),
  name: string(),
  image: optional(XPAImageObjectStruct),
  country: optional(string()),
  countryCode: optional(string()),
});

export type StadiumDataType = Infer<typeof StadiumDataTypeStruct>;
