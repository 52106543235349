import "react-loading-skeleton/dist/skeleton.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./shared/store";
import { initTranslations } from "@/i18n";
import { App } from "@/App";

initTranslations();

const createRootElement = (): HTMLDivElement => {
  const root = document.createElement("div");
  root.setAttribute("id", "root");
  document.body.appendChild(root);
  return root;
};

const rootElement = document.getElementById("root") ?? createRootElement();

ReactDOM.createRoot(rootElement).render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
);
