import { ImageWithFallBack } from "@/shared/ui/blocks/ImageWithFallBack";
import styled from "styled-components";

export const CreatorHeroImg = styled(ImageWithFallBack)`
  width: 100%;
  position: absolute;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
`;
