import styled from "styled-components";
import { GlobalStylesConstants } from "@ui/elements/GlobalStyles/GlobalStylesConstants";

export const SingleFilterListItems = styled.label`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1rem auto max-content;
  gap: 0.5rem;
  align-items: center;
  min-height: ${GlobalStylesConstants.minSizeInteractiveElement};
`;
