import { FC } from "react";
import { WidgetWrapper } from "@ui/blocks/MatchInfoWidget/elements/WidgetWrapper";
import { BodyText } from "@ui/elements/BodyText";
import { TeamsInfoWrapper } from "@ui/blocks/MatchInfoWidget/elements/TeamsInfoWrapper";
import { SectionWrapper } from "@ui/blocks/MatchInfoWidget/elements/SectionWrapper";
import { FontSize } from "@themeTypes/FontSizeType";
import { XPAiconObjectType } from "@/shared/types/XPAiconObjectType";
import { TeamType } from "@/shared/types/TeamType";
import { TitleDruk } from "@ui/elements/TitleDruk";
import { TitleText } from "@ui/elements/TitleText";
import { ImageWithFallBack } from "@ui/blocks/ImageWithFallBack";
import { isNullish } from "@/shared/helpers/isNullish";

interface MatchInfoWidgetProps {
  competition: { name: string; icon: XPAiconObjectType };
  kickoffDate: string;
  kickoffTime: string;
  homeTeam: TeamType;
  awayTeam: TeamType;
  timePeriod: string;
  isDarkMode: boolean;
  venue: string;
}

export const MatchInfoWidget: FC<MatchInfoWidgetProps> = ({
  isDarkMode,
  competition,
  kickoffDate,
  kickoffTime,
  homeTeam,
  awayTeam,
  timePeriod,
  venue,
}) => (
  <WidgetWrapper>
    <SectionWrapper>
      <TitleText>{competition.name}</TitleText>
      <ImageWithFallBack
        width={32}
        height={32}
        src={
          isDarkMode && !isNullish(competition.icon.darkModePath)
            ? competition.icon.darkModePath
            : competition.icon.path
        }
        alt={competition.icon.alt}
      />
    </SectionWrapper>

    <TeamsInfoWrapper>
      <SectionWrapper>
        <ImageWithFallBack
          width={48}
          height={48}
          src={homeTeam.imageObject.path}
          alt={homeTeam.imageObject.alt}
        />
        <TitleDruk>{homeTeam.name}</TitleDruk>
      </SectionWrapper>
      <SectionWrapper>
        <TitleDruk size={FontSize.Title_2}>
          {homeTeam.score} : {awayTeam.score}
        </TitleDruk>
        <BodyText>{timePeriod}</BodyText>
      </SectionWrapper>
      <SectionWrapper>
        <ImageWithFallBack
          width={48}
          height={48}
          src={awayTeam.imageObject.path}
          alt={awayTeam.imageObject.alt}
        />
        <TitleDruk>{awayTeam.name}</TitleDruk>
      </SectionWrapper>
    </TeamsInfoWrapper>
    <SectionWrapper>
      <BodyText size={FontSize.BodyText_7}>{venue}</BodyText>
      <BodyText size={FontSize.BodyText_7}>{kickoffDate}</BodyText>
      <BodyText size={FontSize.BodyText_7}>{kickoffTime}</BodyText>
    </SectionWrapper>
  </WidgetWrapper>
);
