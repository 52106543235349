import { TitleText } from "@ui/elements/TitleText";
import styled from "styled-components";
import { deviceSizeQuery } from "@/shared/constants/deviceSizeQuery";

export const Title2 = styled(TitleText)`
  display: none;

  @media all and ${deviceSizeQuery.tabletLandscape} {
    display: flex;
    align-items: end;
  }
`;
