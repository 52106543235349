import { VideoPlayerWrapper } from "@ui/blocks/VideoPlayerRelatedVideos/elements/VideoPlayerWrapper";
import { isNullish } from "@/shared/helpers/isNullish";
import { OttBanner } from "@ui/blocks/OttBanner";
import { VideoDescription } from "@ui/blocks/VideoDescription";
import { VideosSectionWrapper } from "@ui/blocks/VideoPlayerRelatedVideos/elements/VideosSectionWrapper";
import { FC, useMemo, useState } from "react";
import { VideoPlayer } from "@ui/blocks/VideoPlayer";
import { BAIT_SCRIPT_URL } from "@/shared/constants/baitScriptUrl";
import { VideoPlayerEmpty } from "@ui/blocks/VideoPlayerEmpty";
import { useParams } from "react-router";
import { useLocale } from "@/shared/hooks/useLocale";
import { createCheckoutUrl } from "@features/matchDetail/helpers/createCheckoutUrl";
import { AdBlockerBanner } from "@ui/blocks/AdBlockerBanner";
import Skeleton from "react-loading-skeleton";
import { is } from "superstruct";
import { StreamErrorDataStruct } from "@features/matchDetail/types/StreamsDTO";
import {
  useGetStreamingOptionsQuery,
  useGetStreamQuery,
} from "@features/matchDetail/services/matchDetailsApi";

const DEFAULT_POSTER_IMAGE =
  "https://images.onefootball.com/cw/bg/zigzag-pattern-banner.svg";

export const MatchPlayerContainer: FC = () => {
  const { id } = useParams();
  const { locale, t } = useLocale();

  const apiParams = {
    locale,
    matchId: id ?? "1",
  };

  const { data: streamingOptionsData, isLoading } =
    useGetStreamingOptionsQuery(apiParams);

  const hasStreamingOptions =
    !isNullish(streamingOptionsData) &&
    streamingOptionsData.streaming_options.length > 0;

  const streamId = hasStreamingOptions
    ? streamingOptionsData.streaming_options[0].streams[0].id
    : null;

  const shouldRenderVideoPlayer = useMemo(
    () =>
      hasStreamingOptions &&
      (streamingOptionsData.streaming_options[0].offers[0].is_free ||
        streamingOptionsData.streaming_options[0].offers[0].has_purchased),
    [hasStreamingOptions, streamingOptionsData?.streaming_options],
  );

  const {
    data: streamData,
    error: streamDataError,
    isError: isStreamDataError,
  } = useGetStreamQuery(
    {
      locale,
      streamId: streamId ?? "",
    },
    {
      skip: isNullish(streamId) || !shouldRenderVideoPlayer,
    },
  );

  const [shouldShowScoreInfo, setShouldShowScoreInfo] = useState(false);
  const [isAdBlockerActive, setIsAdBlockerActive] = useState(false);

  const handleAdBlocker = () => {
    setIsAdBlockerActive(true);
  };

  const shouldShowFTAmessage = useMemo(
    () =>
      hasStreamingOptions &&
      streamingOptionsData.streaming_options[0].offers[0].is_free,
    [hasStreamingOptions, streamingOptionsData?.streaming_options],
  );

  const checkoutUrl = useMemo(() => {
    if (isNullish(streamingOptionsData)) {
      return "";
    }
    return createCheckoutUrl({
      locale,
      matchId: streamingOptionsData.metadata.match_id ?? "",
    });
  }, [locale, streamingOptionsData]);

  const MemoizedVideoPlayer = useMemo(() => {
    if (isStreamDataError || !isNullish(streamDataError)) {
      return (
        is(streamDataError, StreamErrorDataStruct) && (
          <AdBlockerBanner
            text={streamDataError.message}
            posterUrl={DEFAULT_POSTER_IMAGE}
          />
        )
      );
    }

    return (
      !isNullish(streamData) &&
      !isNullish(streamData.stream) && (
        <VideoPlayer
          shouldShowAdBlocker={isAdBlockerActive}
          onAdBlockerActive={handleAdBlocker}
          adBlockerBaitScriptUrl={BAIT_SCRIPT_URL}
          config={streamData.stream.videojsPlayer.config}
          playlist={streamData.stream.videojsPlayer.playlist}
        />
      )
    );
  }, [isAdBlockerActive, isStreamDataError, streamData, streamDataError]);

  const RenderEmptyPlaceholder = useMemo(() => {
    if (isLoading) {
      return (
        <div style={{ aspectRatio: "16 / 9" }}>
          <Skeleton width="100%" height="100%" />
        </div>
      );
    }

    if (!hasStreamingOptions) {
      return (
        <AdBlockerBanner
          text={t("NO_STREAM")}
          posterUrl={DEFAULT_POSTER_IMAGE}
        />
      );
    }

    return (
      <VideoPlayerEmpty
        link={{
          url: checkoutUrl,
          name: t(
            "BUY_FOR",
            streamingOptionsData.streaming_options[0].offers[0].price_web,
          ),
        }}
        posterImage={
          streamingOptionsData.streaming_options[0].offers[0].image_url
        }
      />
    );
  }, [
    checkoutUrl,
    hasStreamingOptions,
    isLoading,
    streamingOptionsData?.streaming_options,
    t,
  ]);

  return (
    <VideosSectionWrapper>
      <VideoPlayerWrapper data-testid="video-player">
        {shouldRenderVideoPlayer ? MemoizedVideoPlayer : RenderEmptyPlaceholder}
      </VideoPlayerWrapper>

      {hasStreamingOptions && !shouldRenderVideoPlayer && (
        <>
          <OttBanner
            message={`${t("WATCH_LIVE").toUpperCase()} in partnership with`}
            link={{
              url: checkoutUrl,
              name: t("BUY_FOR", {
                price:
                  streamingOptionsData.streaming_options[0].offers[0].price_web,
              }),
            }}
            broughtBy={streamingOptionsData.streaming_options[0].partner.name}
            logo={{
              src: streamingOptionsData.streaming_options[0].partner.image_url,
              alt: streamingOptionsData.streaming_options[0].partner.name,
            }}
          />
        </>
      )}
      {shouldShowFTAmessage && (
        <>
          <VideoDescription
            title={t("FTA_PLAYER_TITLE", {
              partnerName:
                streamingOptionsData?.streaming_options[0].partner.name,
            })}
            description={t("FTA_PLAYER_DESCRIPTION", {
              match:
                streamingOptionsData?.streaming_options[0].offers[0]
                  .description,
              partnerName:
                streamingOptionsData?.streaming_options[0].partner.name,
            })}
            partnerName={
              streamingOptionsData?.streaming_options[0].partner.name
            }
            partnerImage={{
              src: streamingOptionsData?.streaming_options[0].partner.image_url,
              alt: streamingOptionsData?.streaming_options[0].partner.name,
            }}
          ></VideoDescription>
        </>
      )}
    </VideosSectionWrapper>
  );
};
