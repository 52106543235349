import styled from "styled-components";
import { WithThemeType } from "@themeTypes/WithThemeType";
import { TitleText } from "@ui/elements/TitleText";

export const Price = styled(TitleText)<WithThemeType>`
  border: 1px solid ${(p) => p.theme.colors.border};
  border-radius: ${(p) => p.theme.roundedCorners.regular};
  min-width: 2.75rem;
  min-height: 2.75rem;
  padding: 0.75rem 1.5rem;
`;
