/**
 * Converts a duration in seconds to a formatted string (HH:MM:SS or MM:SS).
 * @param {number} durationInSeconds - The duration in seconds to be formatted.
 * @returns {string} - A formatted string representation of the duration.
 */
export const formatDuration = (durationInSeconds: number): string => {
  const date = new Date(durationInSeconds * 1000);
  const startIdx = durationInSeconds < 3600 ? 14 : 11; // MM:SS if less than 1 hour, HH:MM:SS otherwise
  return date.toISOString().substring(startIdx, 19); // Returns formatted time
};
