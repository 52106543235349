import styled from "styled-components";
import { deviceSizeQuery } from "@/shared/constants/deviceSizeQuery";

export const OFcomButtonList = styled.li`
  display: grid;
  place-content: center;
  padding: 1rem;

  @media all and (${deviceSizeQuery.tabletLandscape}) {
    margin-left: auto;
    padding: 0;
  }
`;
