import { FiltersWrapper } from "@ui/elements/FiltersWrapper";
import {
  AtrributeOptions,
  RefinementListFilters,
} from "@features/refinementListFilters/containers/RefinementListFilters";
import React, { FC, useCallback } from "react";
import { useOutsideClick } from "@/shared/hooks/useOutsideClick";
import { useLocale } from "@/shared/hooks/useLocale";
import { BurgerMenu } from "@ui/blocks/BurgerMenu";
import { FilterButtonWrapper } from "@features/liveMatches/elements/FIlterButtonWrapper";

import filtersIconLight from "/assets/icons/filter_light.svg";
import filtersIconDark from "/assets/icons/filter_dark.svg";
import closeMenu from "/assets/icons/closeMenu.svg";
import closeMenuDark from "/assets/icons/closeMenuDarkMode.svg";
import { BodyScrollBlocker } from "@ui/elements/BodyScrollBlocker";

interface FiltersClipsWrappersProps {
  onFiltersDrawerOutsideClick: () => void;
  filtersDrawerOpened: boolean;
  isDarkTheme: boolean;
}

export const FiltersClipsWrappers: FC<FiltersClipsWrappersProps> = (props) => {
  const { filtersDrawerOpened, onFiltersDrawerOutsideClick, isDarkTheme } =
    props;
  const ref = useOutsideClick(onFiltersDrawerOutsideClick);
  const { t } = useLocale();

  const getFiltersIcon = useCallback(() => {
    if (filtersDrawerOpened) {
      return isDarkTheme ? closeMenuDark : closeMenu;
    }

    return isDarkTheme ? filtersIconDark : filtersIconLight;
  }, [filtersDrawerOpened, isDarkTheme]);

  return (
    <FiltersWrapper ref={ref} opened={filtersDrawerOpened}>
      <BodyScrollBlocker shouldBlockScroll={filtersDrawerOpened} />
      <FilterButtonWrapper>
        <BurgerMenu
          onBurgerClick={() => onFiltersDrawerOutsideClick()}
          iconSrc={getFiltersIcon()}
          iconAlt="filters-icon"
          absolutePosition={{ right: "1.25rem", top: "1rem", left: "auto" }}
        />
      </FilterButtonWrapper>
      <RefinementListFilters
        isDarkTheme={isDarkTheme}
        title={t("FILTER_BY_COMPETITION")}
        attribute={AtrributeOptions.competitions}
      ></RefinementListFilters>

      <RefinementListFilters
        isDarkTheme={isDarkTheme}
        title={t("FILTERS_BY_TEAM")}
        attribute={AtrributeOptions.teams}
      ></RefinementListFilters>

      <RefinementListFilters
        isDarkTheme={isDarkTheme}
        title={t("FILTERS_BY_PLAYER")}
        attribute={AtrributeOptions.players}
      ></RefinementListFilters>
    </FiltersWrapper>
  );
};
