import styled from "styled-components";
import { Button } from "@ui/elements/Button";
import { ZIndexes } from "@/shared/constants/zIndexes";

export const ScrollToTopButtonWrapper = styled(Button)`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 0.625rem;
  margin: 0 auto;

  transition:
    visibility 200ms ease-in-out,
    opacity 200ms ease-in-out;

  position: fixed;
  bottom: 5rem;
  left: 50%;
  transform: translate(-50%);
  z-index: ${ZIndexes.Max};
`;
