import { isNullish } from "@/shared/helpers/isNullish";
import { HorizontalSeparator } from "@ui/elements/HorizontalLine";
import { useParams } from "react-router";
import React, { FC, useMemo } from "react";
import { useLocale } from "@/shared/hooks/useLocale";
import { useGetLiveMatchesRailQuery } from "@features/discovery/services/discoveryApi";
import { RelatedVideosWrapper } from "@ui/blocks/VideoPlayerRelatedVideos/elements/RelatedVideosWrapper";
import { FontSize } from "@themeTypes/FontSizeType";
import { TitleText } from "@ui/elements/TitleText";
import { TeaserGrid } from "@ui/elements/TeaserGrid";
import { RelatedMatches } from "@ui/blocks/RelatedMatches";
import { renderLiveMatchesLoading } from "@features/discovery/helpers/renderLiveMatchesLoading";
import { trackingService } from "@features/tracking/services/trackingService";
import { EventName } from "@/modules/tracking/constants/EventName";
import { SectionTypes } from "@/shared/types/SectionTypes";
import { useGetStreamingOptionsQuery } from "@features/matchDetail/services/matchDetailsApi";

interface SendCardClickedProps {
  itemId: string;
  price?: string;
}

export const RelatedMatchesContainer: FC = () => {
  const { id } = useParams();
  const { locale, t } = useLocale();
  const { data: streamingOptionsData } = useGetStreamingOptionsQuery({
    locale,
    matchId: id ?? "1",
  });

  const { data: relatedVideosData, isLoading } = useGetLiveMatchesRailQuery(
    {
      locale,
      offset: 0,
      competitionID: streamingOptionsData?.metadata.competition_id,
      price: "",
    },
    { skip: isNullish(streamingOptionsData) },
  );

  const MemoisedRelatedMatches = useMemo(() => {
    if (isNullish(relatedVideosData?.upcomingMatches)) {
      return null;
    }

    const sendCardClicked = ({ price, itemId }: SendCardClickedProps) => {
      void trackingService.sendEvent({
        name: EventName.CardClicked,
        properties: {
          card_type: SectionTypes.match,
          price,
          item_id: itemId,
        },
      });
    };

    return (
      <RelatedMatches
        currentMatchId={id ?? "1"}
        items={relatedVideosData.upcomingMatches}
        onCardClick={sendCardClicked}
      />
    );
  }, [id, relatedVideosData?.upcomingMatches]);

  return (
    <div>
      <HorizontalSeparator />
      <TitleText
        fontWeight={700}
        size={FontSize.Title_6}
        style={{ margin: "1rem 0" }}
      >
        {t("RELATED_MATCHES")}
      </TitleText>
      <TeaserGrid>
        {isLoading ? renderLiveMatchesLoading(4) : MemoisedRelatedMatches}
      </TeaserGrid>
    </div>
  );
};
