import styled from "styled-components";
import { deviceSizeQuery } from "@/shared/constants/deviceSizeQuery";
import { limit } from "@ui/elements/GlobalStyles/helpers/limit";
import { WithThemeType } from "@themeTypes/WithThemeType";
import { ZIndexes } from "@/shared/constants/zIndexes";

export const NavLinks = styled.div<
  {
    isMobileVisible: boolean;
  } & WithThemeType
>`
  display: ${({ isMobileVisible }) => (isMobileVisible ? "grid" : "none")};
  position: absolute;
  left: 0;
  width: 100%;
  top: 3.5rem;
  z-index: ${ZIndexes.Max};
  background-color: ${(p) => p.theme.colors.background};
  align-items: center;
  box-shadow: 1px 5px 8px rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid ${(p) => p.theme.colors.elevatedBackground};
  @media all and (${deviceSizeQuery.tabletPortrait}) {
    top: calc(5rem + 1px);
  }
  @media all and (${deviceSizeQuery.tabletLandscape}) {
    ${limit};
    display: flex;
    flex: 1;
    margin-left: initial;
    margin-right: initial;
    width: auto;
    gap: 1.5rem;
    position: initial;
    grid-auto-flow: column;
    height: 100%;
  }
`;
