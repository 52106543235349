import { FC } from "react";
import { getMenuAssets } from "@features/pageLayout/helpers/getMenuAssets";
import { BurgerMenu } from "@ui/blocks/BurgerMenu";
import { useVideoDetailSettings } from "@/shared/hooks/useVideoDetailSettings";
import { MatchInfoWidget } from "@ui/blocks/MatchInfoWidget";
import { utcToLocaleDate } from "@/shared/helpers/utcToLocaleDate";
import { WidgetsSidebar } from "@ui/blocks/WidgetsSidebar";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { WidgetLoader } from "@ui/blocks/WidgetLoader";
import { useGetMatchInfoQuery } from "@features/matchDetail/services/matchDetailsApi";
import { useAppSelector } from "@/shared/hooks/appHooks";
import { selectIsDarkTheme } from "@features/pageLayout/store/pageLayoutSlice";

export const WidgetsContainer: FC = () => {
  const { id } = useParams();
  const { i18n } = useTranslation();
  const isDarkTheme = useAppSelector(selectIsDarkTheme);

  const { data, isLoading, isSuccess } = useGetMatchInfoQuery({
    locale: i18n.resolvedLanguage ?? "en",
    matchId: id ?? "",
  });
  return (
    <div>
      {(isLoading || !isSuccess) && <WidgetLoader />}
      {isSuccess && (
        <MatchInfoWidget
          awayTeam={data.matchScore.awayTeam}
          competition={data.matchScore.competition}
          homeTeam={data.matchScore.homeTeam}
          isDarkMode={isDarkTheme}
          kickoffDate={utcToLocaleDate(data.matchScore.kickoff.utcTimestamp)}
          kickoffTime={data.matchScore.kickoff.time}
          timePeriod={data.matchScore.timePeriod ?? ""}
          venue={data.matchScore.stadium?.name ?? ""}
        />
      )}
    </div>
  );
};
